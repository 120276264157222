import {observable, action} from 'mobx';
import {print, callBack, set, get, date2Day, dateFormat} from '../assets/LocalStorage'
import md5 from '../utils/md5';
import Api from '../utils/Api'
class Speical{
    @observable message = ""
    @action setMessage(m) {
        this.message = m
        setTimeout(()=>{
            this.message = ""
        }, 3500)
    }
    /**
     *  订阅集合
     */
    @observable speical_list = []
    // 加载订阅集合数据
    @action loadSpeicalList(){
        let array = get("speical_list")
        this.speical_list = array == null ? [] : array
    }
    // 保存订阅集合
    @action setSpeicalList(speical) {
        const array = []
        const md5_id = md5(JSON.stringify(speical))
        
        let flag = false
        this.speical_list.map(sp =>{
            if(sp.id != md5_id) {
                array.push({...sp})
            }else {
                flag = true
            }
        })

        if(!flag){
            array.push({
                ...speical,
                id: md5_id
            })

            this.speical_list = array
        
            set('speical_list', this.speical_list)
        }else{
            console.log(`条件已经存在了:${md5_id}`)
        }
    }
    // 订阅名字
    @observable speical_name = ""
    // 订阅媒体集合
    @observable speical_media_list = []
    // 订阅关键字
    @observable speical_key_words = ""

    @action setSpeicalName(name) {
        this.speical_name = name
    }

    @action setSpeicalMedia(medias) {
        this.speical_media_list = medias
    }

    @action setSpeicalKeyWord(words) {
        this.speical_key_words = words
    }

    @action addSpeical(list){
        let speical = {
            name: this.speical_name,
            media_list: [...list.filter(it=>{return it.selected == true}).map(it=>{return {...it}})],
            key_words: this.speical_key_words.split(' ')
        }
        this.setSpeicalList(speical)
        this.speical_name = ""
        this.speical_key_words = ""
        this.setMessage('创建专题成功，请到列表中查看')
    }

    @action deleteSpeical(speical) {
        let array = []
        this.speical_list.map((sp) => {
            if(sp.id != speical.id){
                array.push({...sp})
            }
        })
        this.speical_list = array
        set('speical_list', this.speical_list)
    }
    @observable loading = false
    @observable speical = null
    @observable speical_data_list = []
    @action setSpeical(sp){
        print(`setSpeical`)
        this.speical = sp
        this.speical_data_list = []
        if(sp != null) {

        
        let ids = ''
        this.speical.media_list.map((media, index)=>{
            if(0 == index) {
                ids = media.id  
            }else {
                ids += `,${media.id}`
            }
        })

        let key_words = ''
        this.speical.key_words.map((it, index)=>{
            if(0 == index){
                if(0 == index) {
                    key_words = it  
                }else {
                    key_words += `,${it}`
                }
            }
        })
        // this.loading = true
        Api.search(
            key_words, 
            dateFormat(date2Day(new Date(), -6, ''), '').replace(/\//g,'-'), 
            dateFormat(new Date(), '').replace(/\//g,'-'),
            ids,
            1,
            150,
            "desc").then(data=>{
                data = callBack(data)
                // print(`search`)
                print(data)
                if(data && 200 == data.code){
                    let {
                        chatRoomMessage: {total, list}, // 消息
                        count           // 媒体
                    } = data.data


                    if(list && Array.isArray(list)) {
                        let msg = []
                        list.map((message, index) => {
                            let key = message.key
                            // if(message.ids != "") {
                            //     message.ids.split(',').map(id=>{
                            //         this.data_media_me.map(me_media=>{
                            //             if(me_media.id == id){
                            //                 key += `,${me_media.name}`
                            //             }
                            //         })
                            //     })
                            // }
                            msg.push({
                                ...message,
                                keyNames: key
                            })
                        })
                        this.speical_data_list = msg
                    }
                    
                }
                // print(this.search_message_list)
                // this.loading = false
            }).catch(e=>{
                print(e)
                // this.loading = false
            })

        }
    }
}
const speical = new Speical()
export default speical