import React, {Component} from 'react'
import {get, print, getSession, filterHTMLTag} from '../../assets/LocalStorage'

class MediaListItem extends Component{
    state = {
        messages: []
    }
    timer = null
    componentDidMount(){
        const {task} = this.props
        if(task) {
            this.getMessagesByItemId()
            if(this.timer != null) {
                clearInterval(this.timer)
                this.timer = null
            }
            this.timer = setInterval(()=>{
                this.getMessagesByItemId()
            }, 1000 * 15)
        }  
    }

    getMessagesByItemId() {
        const {item, selectMedia, setMessagesData} = this.props
        this.setState({
            messages: getSession(item.id)
        })
        if(selectMedia && item.id == selectMedia.id && setMessagesData) {
            setMessagesData(this.state.messages)
        }
        // print(`mediaId: ${item.id} => messages: ${JSON.stringify(this.state.messages)}`)
    }

    render() { 
        const {item, onClick, selectMedia, task} = this.props
        let count = 0
        if (task) {
            if(selectMedia) {
                if(selectMedia && item.id == selectMedia.id) {
                    count = 0
                }else if(item.show_time == null) {
                    if(this.state.messages && Array.isArray(this.state.messages)){
                        count = this.state.messages.length
                    }else {
                        count = 0
                    }
                } else if(selectMedia.show_time != null){
                    this.state.messages.map(it => {
                        if(new Date(it.date_time) > selectMedia.show_time) {
                            count += 1
                        }
                    })
                }
            } else {
                count = this.state.messages.length
            }
        }else {
            count = item.count
        }
        
        let max_message = undefined
        if(this.state.messages && Array.isArray(this.state.messages)) {
            max_message = this.state.messages[this.state.messages.length - 1]
        }
        // print(`count: ${count}`) 
        // print(`max_message: ${JSON.stringify(max_message)}`)
        return(
            <div className="text-reset nav-link p-0 mb-6 a-fadein" onClick={()=>onClick?onClick():""}>
                <div className="card card-active-listener">
                    <div className="card-body">
                        <div className="media">
                            <div className={(selectMedia && item.id == selectMedia.id) ? "avatar avatar-online mr-5" : "avatar mr-5"}>
                                <img className="avatar-img" src={item.avatars} alt="Bootstrap Themes"></img>
                            </div>
                            <div className="media-body overflow-hidden">
                                <div className="d-flex align-items-center mb-1">
                                    <h6 className="text-truncate mb-0 mr-auto">{item.name}</h6>
                                    <p className="small text-muted text-nowrap ml-4">{max_message ? max_message.date_time: ""}</p>
                                </div>
                                <div className="text-truncate">{max_message ? max_message.content : ""}</div>
                            </div>
                        </div>
                    </div>
                    {(count > 0)?
                        <div className="badge badge-circle badge-primary badge-border-light badge-top-right">
                            <span>{count <= 99 ? count : '99+'}</span>
                        </div>:""
                    }
                    
                </div>
            </div>
        )
    }
}

export default MediaListItem