import {observable, action} from 'mobx';
import Api from '../utils/Api'
import {print, callBack, set, get, date2Day, dateFormat, getSession, setSession} from '../assets/LocalStorage'

class MainStore{
    // 信息获取定时器
    timer = null

    @observable main_loading = false
    @observable side_loading = false
    @observable message_loading = false
    

    @observable ui_side_status = "0"    // 主要控制那个Plane显示出来 default(0) search(1, 1-1, 1-2) media(2) 
    @action setUiSideStatus(flag) {
        this.ui_side_status = flag
    }
    @observable ui_message_open = false


    @observable data_media_all = []     // 全部媒体集合
    @observable date_media_first_letter_all = null
    /**
     *  用户订阅的媒体集合
     */
    @observable data_media_me = []   
    
    // 消息界面数据
    @observable data_media_messages = []
    @action setMessagesData(arr) {
        this.data_media_messages = arr
    }
    
    @observable search_data_media_me = []           // 用于记录取消的媒体集合
    @action addSearchDataMediaMe(media) {
        let array = []
        this.search_data_media_me.map(_item=>{
            array.push({..._item})
        })
        array.push({...media})
        this.search_data_media_me = array
        print(`addSearchDataMediaMe`)
        print(media)
        print(array)
        print(this.search_data_media_me)
    }
    @action removeSearchDataMediaMe(media){
        let array = []
        this.search_data_media_me.map(_item=>{
            if(_item.id != media.id) {
                array.push({..._item})
            }
        })
        
        this.search_data_media_me = array
    }
    @action isSearchDataMediaSelected(media) {
        let bool = false
        this.search_data_media_me.map(item=>{
            if(item.id == media.id){
                bool = true
            }
        })
        return bool
    }

    @observable search_wrods = ""               // 搜索词
    @action setSearchWords(word){
        this.search_wrods = word
    }
    @observable search_mdeia_list = []
    @observable search_start_date_time = date2Day(new Date(), 0, 'yyyy-MM-dd')    // 开始时间
    @observable search_end_date_time = date2Day(new Date(), 0, 'yyyy-MM-dd')       // 结束时间
    @action setSearchStartDate(start) {
        this.search_start_date_time = start
        if (this.search_start_date_time > this.search_end_date_time) {
            this.search_end_date_time = this.search_start_date_time
        }
    }
    @action setSearchEndDate(end){
        this.search_end_date_time = end
        if (this.search_end_date_time < this.search_start_date_time) {
            this.search_start_date_time = this.search_end_date_time
        }
    }
    @observable search_sort = "desc"            // 搜索排序规则
    @action setSearchSort(){
        if(this.search_sort == "desc") {
            this.search_sort = "asc"
        }else {
            this.search_sort = "desc"
        }
    }

    @observable search_media_list = []      // 搜索结果 媒体集合
    @observable search_message_list = []    // 搜索结果 消息集合

    @observable data_media = null       // 当前选中的媒体
    @observable data_message_list = []  // 当前选中的媒体消息

    @observable isUpdataMessageUI = false //是否更新消息滚筒条
    @action setIsUpdataMessageUI(flag) {
        this.isUpdataMessageUI = flag
    }

    @observable ui_source_dalog_show = false   // 搜索关注媒体集合弹出框
    @action setUiSourceDalogShow(flag){
        this.ui_source_dalog_show = flag
    }

    // 选择媒体
    @action setDataMedia(_it) {
        this.data_media = {
            ..._it,
            show_time: new Date()
        }
        this.data_media_messages = getSession(_it.id)
        let array = []
        this.data_media_me.map(it=>{
            if(it.id == this.data_media.id) {
                array.push({
                    ...this.data_media
                })
            }else {
                array.push({
                    ...it
                })
            }
        })

        this.data_media_me = array
        this.isUpdataMessageUI = true
        
        setTimeout(()=>{
            document.getElementById('container_body').scrollTop = document.getElementById('container').scrollHeight  
        }, 150)
        
        this.ui_message_open = true
    }


    @action clearDataMedia(){
        this.ui_message_open = false
    }

    @observable ui_message_right_sidebar_open = false
    @action setMessageRightSideBarOpen(flag){
        this.ui_message_right_sidebar_open = flag
    }

    // 获取关注的媒体
    @action initMainData() {
        this.main_loading = true
        Api.getMediaListByToken().then(data=> {
            data = callBack(data)
            if(data && 200 == data.code) {

                this.findMediaMessagesByMe(this.structMediaData(data.data), (media_me_list)=>{
                    if(media_me_list.length > 0) {
                        // 设置我的关注媒体列表
                        this.data_media_me = media_me_list

                        // 初始化搜索关注媒体列表
                        let arr = []
                        media_me_list.map(media=>{
                            arr.push({
                                ...media,
                                selected: true,
                                onClick:(it)=>{
                                    if(it.selected) {
                                        let num = this.search_mdeia_list.length
                                        this.search_mdeia_list.map(t=>{
                                            if(!t.selected) {
                                                num = num - 1 
                                            }
                                        })

                                        if(num - 1 > 0){
                                            let data = {
                                                ...it,
                                                selected: !it.selected
                                            }
                                            this.search_mdeia_list = this.resetListByItem(this.search_mdeia_list, data)    
                                        } else {

                                        }
                                    } else {
                                        let data = {
                                            ...it,
                                            selected: !it.selected
                                        }
                                        this.search_mdeia_list = this.resetListByItem(this.search_mdeia_list, data)    
                                    }
                                    
                                }
                            })
                        })
                        this.search_mdeia_list = arr
                    
                        this.setDataMedia(this.data_media_me[0])
                    
                        document.getElementById('container_body').scrollTop = document.getElementById('container').scrollHeight  
                        if(this.timer != null) {
                            clearInterval(this.timer)
                            this.timer = null
                        }
                        this.timer = setInterval(()=>{
                            print(`timer`)
                            this.findMediaMessagesByMe(this.data_media_me)
                        }, 10 * 1000)
                    }
                    this.main_loading = false
                })

            }
        }).catch(e=>{
            
        })
    }

    
    // 根据我关注的媒体获取媒体最新消息
    @action findMediaMessagesByMe(array, callback) {

        let media_messages_task = []
    
        array.map(item => {
            media_messages_task.push(
                Api.findMeessageNewByMediaId(item.id)
            )
            
        })
        if(media_messages_task.length > 0) {
            Promise.all(media_messages_task).then(res=>{
                if(res 
                  && Array.isArray(res) 
                  && res.length == array.length) {
      
                      res.map((messages, index) => {
                          messages = callBack(messages)
                          // print(`messages: ${JSON.stringify(messages)}`)
                          if(messages && messages.code == 200) {
                              // 媒体对象
                              const me = array[index]
                              // 媒体消息集合
                              let me_message = []             
                              if(getSession(me.id)) {
                                  me_message = [...getSession(me.id)]
                              }
      
                              // 获取媒体消息最大的时间
                              let max_date_time = null
                              if (me_message.length > 0) {
                                  max_date_time = new Date(me_message[me_message.length - 1].date_time)
                              }else {
                                  max_date_time = new Date('1970/01/01 00:00:00')
                              }
      
                              // 将新获取的消息排除重复添加到媒体消息集合中
                              messages.data.map(m => {
                                  m.date_time = m.date_time.replace(/\-/g, '/')
                                  // m.date_time = new Date(m.date_time.replace(/-/g, '/'))
                                  if(new Date(m.date_time) > max_date_time) {
                                      me_message.push({
                                          ...m
                                      })
                                  }
                              })
                              
                              setSession(me.id, me_message.sort((a, b) => {
                                  return new Date(a.date_time) > new Date(b.date_time) ? 1 : -1
                              }))
                          }
      
                      })
      
                      if(callback) {
                          callback(array)
                      }
                      print(`findMediaMessagesByMe OK`)
                }  
              }).catch(e=>{
                  print(`findMediaMessagesByMe ERROR`)
                  print(e)
                  if(this.main_loading) {
                      this.main_loading = false
                  }
              })
        }else{
            if(this.main_loading) {
                this.main_loading = false
            }
        }
        
    }

    // 重制list里面的Item对象生成新的集合
    @action resetListByItem(dataList, item){
        let array = []
        dataList.map((it, index) => {
            if(it.id == item.id) {
                array.push({
                    ...item,
                })
            }else {
                array.push({
                    ...it
                })
            }
        })
        return array
    }

    // 根据媒体ID获取最新的消息
    @action  findMeessageNewByMediaId(mediaId){
        return  Api.findMeessageNewByMediaId(mediaId)
    }

    // 获取全部媒体
    @action getMediaAll() {
        this.side_loading = true
        Api.getMediaListAll().then(data=>{
            data = callBack(data)
            if(data && 200 == data.code){
                this.data_media_all = this.structMediaData(data.data)
                // this.date_media_first_letter_all = this.structMediaDataFirstLetter(data.data)  
            }
            this.side_loading = false
        }).catch(e=>{
            print(e)
            this.side_loading = false
        })
    }
    // 添加媒体关注
    @action addMedia(media) {
        this.side_loading = true
        Api.addMediaByMediaId(media.id).then(data=>{
            data = callBack(data)
            if(data && 200 == data.code) {
                let array = [...this.data_media_me]
                array.push({
                    ...media,
                    messages:[],
                    show_time: null,
                    avatars: this.structMediaAvatars(media.avatars)
                })
                this.data_media_me = array.sort((a, b) => {return a.sort - b.sort})
                this.side_loading = false
            }
        }).catch(e=>{})
    }
    // 取消媒体关注
    @action removeMedia(media) {
        this.side_loading = true
        Api.removeMedia(media.id).then(data=>{
            data = callBack(data)
            if(data && 200 == data.code) {
                let array = []
                this.data_media_me.map(item=>{
                    if(item.id != media.id) {
                        array.push({...item})
                    }
                })
                this.data_media_me = array.sort((a, b) => {return a.sort - b.sort})

                this.side_loading = false
            }
        }).catch(e=>{})
    }
    // 格式化头像
    structMediaAvatars(avatars) {
        if(/\d.png/.test(avatars)) {
            avatars = `assets/images/avatars/${avatars.split('.')[0]}.jpg`
        }
        return avatars
    }

    // 格式化媒体数据
    structMediaData(medias){
        let array = []
        medias.map(item=>{
            // 处理头像兼容
            let avatars = this.structMediaAvatars(item.avatars)
            array.push({
                ...item,
                messages:[],
                avatars: avatars,
                show_time: null,  
            })
        })

        return array.sort((a, b) =>{return a.sort - b.sort})
    }

    structMediaDataFirstLetter(medias) {
        let mediaMap = new Map()
        medias.map(media => {
            let tag = window.pinyinUtil.getFirstLetter(media.name.substring(0, 1))
            if(mediaMap.has(tag)) {
                let tagArr = mediaMap.get(tag)
                tagArr.push({...media})
                mediaMap.set(tag, [...(tagArr.sort((a, b) => {return a.sort - b.sort}))])
            } else {
                let tagArr = []
                tagArr.push({...media})
                mediaMap.set(tag, [...tagArr])
            }
        })
        
        return mediaMap
    }

    translate(content, isChinese = true){
        let from = 'nzh'
        let to = 'nen'
        if(!isChinese) {
            from = 'nen'
            to = 'nzh'
        }
        return Api.translate(content, from, to)
    }

    shareTxtToServer(item, tContent) {
        let params = {
            "list": []
        } 
        params.list.push({
            type: "text",
            text: tContent,
            // text_translate: tContent,
            id: item.id,
            source: item.key,
            createDate: item.date_time.replace(/\//g, '-'),
            title: item.title
        })
        return Api.getShareImg(params)
    }

    // 搜索
    @action search(){
        this.search_message_list = []
        this.search_media_list = []

        this.side_loading = true

        let ids = ""
        this.search_mdeia_list.map((media, index)=>{
            if(media.selected) {
                if(0 == index) {
                    ids = media.id  
                }else {
                    ids += `,${media.id}`
                }
            }
        })

        Api.search(
            this.search_wrods, 
            dateFormat(this.search_start_date_time, '').replace(/\//g,'-'), 
            dateFormat(this.search_end_date_time, '').replace(/\//g,'-'),
            ids,
            1,
            150,
            this.search_sort).then(data=>{
                data = callBack(data)
                // print(`search`)
                // print(data)
                if(data && 200 == data.code){
                    let {
                        chatRoomMessage: {total, list}, // 消息
                        count           // 媒体
                    } = data.data

                    this.search_media_list = this.structMediaData(count).filter(media=>{
                        return media.count > 0
                    })

                    if(list && Array.isArray(list)) {
                        let msg = []
                        
                        list.map((message, index) => {
                            let key = message.key
                            if(message.ids != "") {
                                message.ids.split(',').map(id=>{
                                    this.data_media_me.map(me_media=>{
                                        if(me_media.id == id){
                                            key += `,${me_media.name}`
                                        }
                                    })
                                })
                            }
                            msg.push({
                                ...message,
                                keyNames: key
                            })
                        })
                        this.search_message_list = msg
                    }
                    
                }
                // print(this.search_message_list)
                this.side_loading = false
            }).catch(e=>{
                print(e)
                this.side_loading = false
            })
    }

}

const mainStore = new MainStore();
export default mainStore