import request from './Request';
import md5 from './md5';
import {getToken, clearUserInfo} from '../assets/LocalStorage'

/**
 * 登陆
 * @param {*} name 账号
 * @param {*} password 密码
 * 
 * @returns 1 用户登陆标示   token
 *          2 用户昵称      nickName    
 *          3 用户头像      avatars
 *          4 用户类别      category 可以发空
 * 
 *
 */
function signin(name, password) {
    return request.postApi(`${request.HOST}/user/sys/login?tag=${request.TAG}`, {loginName: name, password: md5(password)})
    // return request.post(`/login`, {name: name, pwd: md5(password)})
}

function register(name, pwd){
    return request.postApi(`${request.HOST}/user/sys/register?tag=${request.TAG}`, {loginName: name, password: md5(pwd)})
    // return request.post(`/register`, {name: name, pwd: md5(pwd)})
}

/**
 * 登出
 */
function signout(){

}
/**
 * 检查token 检查token是否可用
 * @returns  不可用/可用 
 */
function checkToken(){

}
/**
 * 修改秘密
 * @param token
 * @param {*} newpass 
 * @returns 1 用户登陆标示   token 新的
 * 
 */
function resetpass(newpass) {

}
/**
 * 获取我的订阅媒体列表
 * @param {*} token 
 * 
 * @returns 集合类型
            id = ""             //  标示 *
            name = ""           //  名称 *
            avatars = ""        //  头像或标示 图片 *
            category = ""       //  类别
            star = 0            //  级别
            language = ""       //  语言
            describe = ""       //  描述
            descriptive = ""    //  地域
            status = 0          //  媒体状态  数据正常 数据异常
            sort = 0            //  顺序 列表顺序
            isDelete            //  是否可以取消订阅  有媒体是不可以取消订阅的 *

    * 符号的必须要有
 */
function getMediaListByToken() {
    return request.getApi(`${request.HOST_TEA}/userchat/list`, getToken())
    // return request.get(`/media/list`)
}
/**
 * 订阅媒体
 * @param token
 * @param mediaId
 * 
 * @returns 返回我订阅媒体列表
 */
function addMediaByMediaId(mediaId){
    return request.postApi(`${request.HOST_TEA}/userchat/add/${mediaId}`, getToken())
}

/**
 * 取消订阅媒体
 * @param token
 * @param mediaId
 * 
 * @returns 返回我订阅媒体列表
 */
function removeMedia(mediaId){
    return request.postApi(`${request.HOST_TEA}/userchat/remove/${mediaId}`, getToken())
}
/**
 * 获取全部媒体频道列表
 * @param {*} token
 * 订阅媒体列表 
 */
function getMediaListAll(){
    return request.getApi(`${request.HOST_TEA}/chat/list`) //request.get(`/media/all`)
}


/**
 * 获取媒体频道最新数据
 * @param {*} token
 * @param {*} mediaId 
 * 
 * @returns []集合
 *           id = ""  //标示 *
            title = ""  //标题 *
            content = "" //内容 *
            date_time = "" //时间 *
            from = ""  //渠道 *
            star = 0 //重要等级     
 */
function findMeessageNewByMediaId(mediaId, num=10){
    return request.getApi(`${request.HOST_TEA}/chat/message/${mediaId}/${num}`)
    // return request.get(`/media/message/list/${mediaId}/${num}`)
}
/**
 * 根据搜索词获取媒体包含信息数量 POST
 * @param {*} token 用户登陆token
 * @param {*} search 搜索词
 * @param {*} mediaIds 媒体id
 * @param {*} start YYYY-dd-MM 
 * @param {*} end YYYY-dd-MM
 * @param {*} sort 排序自动 升序/降序  
 * 
 *          id = ""             //  标示 *
            name = ""           //  名称 *
            avatars = ""        //  头像或标示 图片 *
            category = ""       //  类别
            star = 0            //  级别
            language = ""       //  语言
            describe = ""       //  描述
            descriptive = ""    //  地域
            status = 0          //  媒体状态  数据正常 数据异常
            sort = 0            //  顺序 列表顺序
            isDelete = 0        //  是否可以取消订阅  有媒体是不可以取消订阅的 *
            num = 0             //  包涵数据多少条 *
 * 
 */
function searchMediaList(search, start, end, sort='desc', mediaIds = []) {
    return request.post(`/search/media/list`, {
        search_words: search,
        mediaIds: mediaIds,
        start_date_time: start,
        end_date_time: end,
        sort: sort
    })
}

/**
 * 根据搜索词和媒体ID获取搜出来的信息 分页 POST

 * @param {页码 1开始} page 
 * @param {每页多少条数据} count 
 * @param {用户登陆token} token 
 * @param {搜索词} search 
 * @param {媒体id} mediaIds 
 * @param {YYYY-dd-MM 开始时间} start 
 * @param {YYYY-dd-MM 结束时间} end 
 * @param {排序自动 升序/降序 asc/desc} sort 
 * @param {请求时间毫秒数} dateTime
 * 
 *  @returns []集合
 *              id = ""  //标示 *
                title = ""  //标题 *
                content = "" //内容 *
                date_time = "" //时间 *
                from = ""  //渠道 *
                star = 0 //重要等级  
 */
function searchMediaMessageList(search, start, end, sort='desc', mediaIds = []) {
    return request.post(`/search/media/all/message`, {
        search_words: search,
        mediaIds: mediaIds,
        start_date_time: start,
        end_date_time: end,
        sort: sort
    })
}

function search(search, start, end, mediaIds = [], page, total, sort='desc'){
    return request.postApi(`${request.HOST_TEA}/chat/message/search/count/${page}/${total}`,{
        search_words: search,
        start_date_time: start,
        end_date_time: end,
        sort: sort,
        ids: mediaIds
    }, getToken())
}


function translate(content, from, to) {
    return request.postApi(`https://www.vulcandata.cn/mer/t/translate`, {
        content: content,
        srcl: from,
        tatl: to
    }, getToken())
}

function getShareImg(param) {
    return request.postApi(`${request.HOST_BASE}/mer/graphics/combination1`, param, getToken())
}


function createAudio(message){
    return request.postApi(`${request.HOST_BASE}/tea/test/speech`, {content: message.content}, getToken())
}

function createVedio(message){
    return request.postApi(`${request.HOST_BASE}/mer/videoTask/addVulcan`, {
        "txtDataSet":[
            {
                title: message.title,
                txt: message.content,
                sort: 1
            }
        ]
    }, getToken())
}

function getAudioStatus(id) {
    return request.getApi(`${request.HOST_BASE}/tea/test/sp?name=${id}`, getToken())
}

function getVedioStatus(id) {
    return request.getApi(`${request.HOST_BASE}/mer/videoTask/status?id=${id}`, getToken())
}


export default {
    signin,
    register,
    getMediaListByToken,
    findMeessageNewByMediaId,
    getMediaListAll,
    // searchMediaList,
    // searchMediaMessageList,
    addMediaByMediaId,
    removeMedia,
    search,
    translate,
    getShareImg,
    createAudio,
    createVedio,
    getAudioStatus,
    getVedioStatus
}