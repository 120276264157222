import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';

@inject("store")
@observer
class MessageRightSideBar extends Component{

    componentDidMount(){
        // 第一次组件加载数据
        this.updateData()
    }


    componentWillReceiveProps(){
        console.log(`componentWillReceiveProps`)
        // 当数据发生变化
        this.updateData()
    }


    updateData(){
        const {
            mmStore
        } = this.props.store
        const {
            mainStore: {
                data_media,
            }
        } = this.props.store
        mmStore.loadMultiMediaList(data_media.id)
        mmStore.getTaskList(data_media.id)
    }


    render(){
        const {
            mainStore: {
                ui_message_right_sidebar_open,
                data_media,
            },
            mmStore: {
                audio_data_list,
                vedio_data_list
            }
        } = this.props.store
        const {
            mainStore
        } = this.props.store
        return(
            <div className={ui_message_right_sidebar_open?"chat-sidebar chat-sidebar-visible a-fadein" : "chat-sidebar "}>
                <div className="d-flex h-100 flex-column">
                {/* nav */}
                <div className="border-bottom py-4 py-lg-6">
                    <div className="container-fluid">

                        <ul className="nav justify-content-between align-items-center">
                                        
                            <li onClick={()=>mainStore.setMessageRightSideBarOpen(false)} className="nav-item list-inline-item">
                                <a className="nav-link text-muted px-0" href="#" data-chat-sidebar-close="">
                                    <i className="icon-md fe-chevron-left"></i>
                                </a>
                            </li>

                                        
                            <li className="text-center d-block d-lg-none">
                                <h6 className="mb-n2"></h6>
                                <small className="text-muted"></small>
                            </li>

                            <li className="nav-item list-inline-item">
                                <div className="dropdown">
                                    <a className="nav-link text-muted px-0" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="icon-md fe-sliders"></i>
                                    </a>
                                    <div className="dropdown-menu">
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            Mute
                                            <span className="ml-auto fe-bell"></span>
                                        </a>
                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                            Delete
                                            <span className="ml-auto fe-trash-2"></span>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
                {/* userinfo */}
                <div className="hide-scrollbar ">
                    <div className="border-bottom text-center py-9 px-10">         
                        <div className="avatar avatar-xl mx-5 mb-5">
                            <img className="avatar-img" src={data_media ? data_media.avatars : ""} alt="" />
                        </div>
                        <h5>{data_media ? data_media.name : ""}</h5>
                        {/* <p className="text-muted">AI 人工智能金融大数据.</p> */}
                    </div>
                </div>
                {/* tabs */}
                <ul className="nav nav-tabs nav-justified bg-light rounded-0" role="tablist">
                    <li className="nav-item">
                        <a href="#chat-id-1-members" className="nav-link active" data-toggle="tab" role="tab" aria-selected="true">音频</a>
                    </li>
                    <li className="nav-item">
                        <a href="#chat-id-1-files" className="nav-link" data-toggle="tab" role="tab">视频</a>
                    </li>
                </ul>
                {/* 内容 */}
                <div className="tab-content">
                    {/* tab1 */}
                    <div id="chat-id-1-members" className="tab-pane fade show active">
                        <ul className="list-group list-group-flush list-group-no-border-first">
                            {
                                audio_data_list.map((message, index)=>{
                                    return(
                                        <ListItem key={`${message.id}-${index}`} dataItem={message}></ListItem>
                                    )
                                })
                            }
                            
                        </ul>
                    </div>
                    {/* tab2 */}
                    <div id="chat-id-1-files" className="tab-pane fade">
                        <ul className="list-group list-group-flush list-group-no-border-first">
                            {
                                vedio_data_list.map((message, index)=>{
                                    return(
                                        <ListItem key={`${message.id}-${index}`} dataItem={message}></ListItem>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

                </div>
            </div>
        )
    }
}


class ListItem extends Component{
    render(){
        const dataItem = this.props.dataItem
        return(
            <li className="list-group-item py-6">
                <div className="media align-items-center">
                    
                    {/* <div className="avatar avatar-sm avatar-online mr-5">
                        <img className="avatar-img" src="./assets/images/avatars/10.jpg" alt="Anna Bridges" />
                    </div> */}
                                                    
                                                    
                    <div className="media-body">
                        <h6 className="mb-0">
                            {
                                (dataItem.url && dataItem.url.length > 0) ?
                                <a target="_target" href={dataItem.url} className="text-reset">{dataItem.message_content}</a>
                                :
                                <div className="text-reset">{dataItem.message_content}</div>
                            }
                            
                        </h6>
                        <small className="text-muted">{(dataItem.url && dataItem.url.length > 0) ? "完成" : "未完成"}</small>
                    </div>

                    {/* <div className="align-self-center ml-5">
                        <div className="dropdown">
                            <a href="#" className="btn btn-sm btn-ico btn-link text-muted w-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-more-vertical"></i>
                            </a>
                                            
                            <div className="dropdown-menu">
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    Promote <span className="ml-auto fe-trending-up"></span>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    Restrict <span className="ml-auto fe-trending-down"></span>
                                </a>
                                <a className="dropdown-item d-flex align-items-center" href="#">
                                    Delete <span className="ml-auto fe-user-x"></span>
                                </a>
                            </div>
                        </div>
                    </div> */}

                </div>
            </li>
        )
    }
}

export default MessageRightSideBar