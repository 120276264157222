import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';

@inject("store")
@observer
class Navigation extends Component{
    render(){
        const {mainStore} = this.props.store
        const {mainStore: {ui_side_status}} = this.props.store
        return(
            <Fragment>
                <a href="#" className="d-none d-xl-block mb-6">
                    {/* <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="" x="0px" y="0px" viewBox="0 0 46 46" enable-background="new 0 0 46 46" xml:space="preserve" className="injected-svg mx-auto fill-primary" style="height: 46px;">
                        <polygon opacity="0.7" points="45,11 36,11 35.5,1 "></polygon>
                        <polygon points="35.5,1 25.4,14.1 39,21 "></polygon>
                        <polygon opacity="0.4" points="17,9.8 39,21 17,26 "></polygon>
                        <polygon opacity="0.7" points="2,12 17,26 17,9.8 "></polygon>
                        <polygon opacity="0.7" points="17,26 39,21 28,36 "></polygon>
                        <polygon points="28,36 4.5,44 17,26 "></polygon>
                        <polygon points="17,26 1,26 10.8,20.1 "></polygon>
                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" version="1.1" id="" x="0px" y="0px" viewBox="0 0 46 46" enable-background="new 0 0 46 46" xml:space="preserve" className="injected-svg mx-auto fill-primary" style="height: 46px;">
                            <polygon opacity="0.7" points="45,11 36,11 35.5,1 "></polygon>
                            <polygon points="35.5,1 25.4,14.1 39,21 "></polygon>
                            <polygon opacity="0.4" points="17,9.8 39,21 17,26 "></polygon>
                            <polygon opacity="0.7" points="2,12 17,26 17,9.8 "></polygon>
                            <polygon opacity="0.7" points="17,26 39,21 28,36 "></polygon>
                            <polygon points="28,36 4.5,44 17,26 "></polygon>
                            <polygon points="17,26 1,26 10.8,20.1 "></polygon>
                        </svg>
                    </svg> */}
                </a>


                <ul className="nav navbar-nav flex-row flex-xl-column flex-grow-1 justify-content-between justify-content-xl-center py-3 py-lg-0" role="tablist">

                    {/* <!-- Invisible item to center nav vertically --> */}
                    {/* <li className="nav-item d-none d-xl-block invisible flex-xl-grow-1">
                        <a className="nav-link position-relative p-0 py-xl-3" href="#" title="">
                            <i className="icon-lg fe-x"></i>
                        </a>
                    </li> */}

                    {/* <!-- Create group --> */}
                    <li className="nav-item" >
                        <a className={ui_side_status=="4"?"nav-link position-relative p-0 py-xl-3 active":"nav-link position-relative p-0 py-xl-3"}  title="全部媒体" role="tab" onClick={()=>mainStore.setUiSideStatus("4")}>
                            <i className="icon-lg fe-edit"></i>
                            {ui_side_status=="4"?<div className="badge badge-dot badge-primary badge-bottom-center"></div>:""}
                        </a>
                    </li>

                    {/* <!-- Friend --> data-toggle="tab" href="#tab-content-friends"*/}
                    <li className="nav-item mt-xl-9">
                        <a className={ui_side_status=="2"?"nav-link position-relative p-0 py-xl-3 active":"nav-link position-relative p-0 py-xl-3"}  title="全部媒体" role="tab" onClick={()=>mainStore.setUiSideStatus("2")}>
                            <i className="icon-lg fe-users"></i>
                            {ui_side_status=="2"?<div className="badge badge-dot badge-primary badge-bottom-center"></div>:""}
                        </a>
                    </li>

                    {/* <!-- Chats --> data-toggle="tab" href="#tab-content-dialogs"*/}
                    <li className="nav-item mt-xl-9">
                        <a className={ui_side_status=="0"?"nav-link position-relative p-0 py-xl-3 active":"nav-link position-relative p-0 py-xl-3"}  title="关注媒体" role="tab" onClick={()=>mainStore.setUiSideStatus("0")}>
                            <i className="icon-lg fe-message-square"></i>
                            {ui_side_status=="0"?<div className="badge badge-dot badge-primary badge-bottom-center"></div>:""}
                        </a>
                    </li>

                    {/* <!-- Profile --> */}
                    <li className="nav-item mt-xl-9" >
                        <a className={ui_side_status=="3"?"nav-link position-relative p-0 py-xl-3 active":"nav-link position-relative p-0 py-xl-3"}  title="关注媒体" role="tab" onClick={()=>mainStore.setUiSideStatus("3")}>
                            <i className="icon-lg fe-user"></i>
                            {ui_side_status=="3"?<div className="badge badge-dot badge-primary badge-bottom-center"></div>:""}
                        </a>
                    </li>

                    {/* <!-- Demo only: Documentation --> */}
                    <li className="nav-item mt-xl-9" >
                        <a className="nav-link position-relative p-0 py-xl-3"  role="tab">
                            <i className="icon-lg fe-layers"></i>
                        </a>
                    </li>

                    {/* <!-- Settings --> */}
                    <li className="nav-item mt-xl-9" style={{display:'none'}}>
                        <a className="nav-link position-relative p-0 py-xl-3" title="Settings">
                            <i className="icon-lg fe-settings"></i>
                        </a>
                    </li>

                </ul>
            </Fragment>
        )
    }
}

export default Navigation