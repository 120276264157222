import React, {Component, Fragment} from 'react';
import Loading from '../../component/MinLoading'
import {observer, inject} from 'mobx-react';
import {checkChinese, callBack, filterHTMLTag} from '../../assets/LocalStorage'
@inject("store")
@observer
class MessageListItem extends Component{

    state = {
        loading: false,
        translate_title: "", // 翻译标题
        translate_content: "", // 翻译的内容
        share_img_url: "",
        translate_share_image_url: ""
    }
    isTitleChinese = true
    isContentChinese = true

    translate(title, content) {
        const {mainStore} = this.props.store
        let request_list = []
        if(content || content.length) {
            request_list.push(mainStore.translate(content, this.isContentChinese))
        }
        if(title && title.length > 0) {
            request_list.push(mainStore.translate(title, this.isTitleChinese)) 
        }
        this.setState({loading: true})
        Promise.all(request_list).then(data=>{
            let res_content = data[0]
            res_content = callBack(res_content)
            let tc = ""
            let tt = ""
            if(res_content && 200 == res_content.code) {
                tc = res_content.data
            }
            if(data.length == 2) {
                let res_title = data[1]
                res_title = callBack(res_title)
                if(res_title && 200 == res_title.code) {
                    tt = res_title.data
                }
            }
            this.setState({
                translate_title: tt,
                translate_content: tc,
                loading: false
            })
        }).catch(e=>{
            this.setState({loading: false})
        })
    }

    shareImage(item, content, call = (url)=>{
        this.setState({
            loading: false,
            share_img_url: url
        })
    }) {
        const {mainStore} = this.props.store 
        this.setState({loading: true})
        // item.content = content
        mainStore.shareTxtToServer(item, content).then(data=>{
            console.log(data)
            data = callBack(data)
            if(data && 200 == data.code) {
                if(call) {
                    call(data.data)
                }
            }else {
                this.setState({
                    loading: false,
                })
            }
        }).catch(e=>{
            this.setState({loading: false})
        })
    }

    renderDivider(tab){
        let style = {height:'1px', borderBottom:'1px dashed #4d4d4d'}
        return(
            <div className="message-divider mt-1 mb-1">
                <div className="row align-items-center">
                    <div className="col" style={style}>
                        <hr/>
                    </div>
                    <div className="col-auto">
                        <small className="text-muted">{tab}</small>
                    </div>
                    <div className="col" style={style}>
                        <hr/>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {item} = this.props
        this.isTitleChinese = checkChinese(item.title)
        this.isContentChinese = checkChinese(item.content)
        return(
            <div className="text-reset nav-link p-0 mb-6" style={{position: "relative"}}>
                {this.state.loading ? <Loading></Loading> : ""}
                
                <div className="card card-active-listener">
                    <div style={{paddingLeft: '1.5rem', paddingRight: '1.5rem'}} className="d-flex flex-row-reverse">
                        
                        <div style={{height: 30}} className="align-self-center ml-3">
                                <div className="dropdown z-index-max show">
                                    <a href="#" className="btn btn-sm btn-ico btn-link text-muted w-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                        <i className="fe-more-horizontal"></i>
                                    </a>
                                    <div className="dropdown-menu" style={{position: 'absolute', transform: 'translate3d(-122px, 36px, 0px)', top: '0px', left: '0px', wilChange: 'transform'}} x-placement="bottom-start">
                                        <a onClick={()=>{
                                            this.translate(item.title, item.content)
                                        }} className="dropdown-item d-flex align-items-center" href="#">
                                            翻译内容 <span className="ml-auto fe-edit-3"></span>
                                        </a>
                                        <a onClick={()=>{
                                            this.shareImage(item, item.content)
                                        }} className="dropdown-item d-flex align-items-center" href="#">
                                            生成图片 <span className="ml-auto fe-share-2"></span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="card-body" style={{paddingTop: 0}}>
                        <div className="media">
                            <div className="media-body overflow-hidden">
                                
                                <div className="d-flex align-items-center mb-1">
                                    <h6>{item.title}</h6>
                                </div>
                                <div className="mb-1">{filterHTMLTag(item.content)}</div>
                                {
                                    item.keyNames.split(',').length <= 3 ?
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="small text-muted text-nowrap">{item.keyNames}</span>
                                        <span className="small text-muted text-nowrap">{item.date_time.replace(/-/g, '/')}</span>
                                    </div>:
                                    <Fragment>
                                    <div className="d-flex align-items-center justify-content-between mb-1">
                                        <span className="small text-muted text-nowrap"></span>
                                        <span className="small text-muted text-nowrap">{item.keyNames}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span className="small text-muted text-nowrap"></span>
                                        <span className="small text-muted text-nowrap">{item.date_time.replace(/-/g, '/')}</span>
                                    </div>
                                    </Fragment>
                                }

                                {this.state.share_img_url != "" ? this.renderDivider('可分享的图片') : ""}
                                {this.state.share_img_url != "" ? 
                                <div className="form-row py-3">
                                    <div className="col">
                                        <img className="img-fluid rounded" src={this.state.share_img_url} data-action="zoom" alt=""></img>
                                    </div>
                                </div>: ""}

                                {(this.state.translate_title != "" || this.state.translate_content != "") ? this.renderDivider('译文') : ""}
                                {(this.state.translate_title != "" || this.state.translate_content != "") ? <div onClick={()=>{
                                            console.log(this.state.translate_content)
                                            this.shareImage(item, this.state.translate_content, (url)=>{
                                                this.setState({
                                                    loading: false,
                                                    translate_share_image_url: url
                                                })
                                            })
                                        }} style={{color: 'blue'}} className="p2 d-flex flex-row align-items-center justify-content-center"><span style={{color: '#fff'}}  className="mx-2 ml-auto fe-share-2"></span></div>:""}
                                {this.state.translate_title != "" ? <h6 className="mb-2">{this.state.translate_title}</h6>:""}
                                {this.state.translate_content != "" ? <div className="mt-1">{this.state.translate_content}</div>:""}
                                
                                {this.state.translate_share_image_url != "" ? this.renderDivider('可分享的图片') : ""}
                                {this.state.translate_share_image_url != "" ? 
                                <div className="form-row py-3">
                                    <div className="col">
                                        <img className="img-fluid rounded" src={this.state.translate_share_image_url} data-action="zoom" alt=""></img>
                                    </div>
                                </div>: ""}
                                
                                {/* <div className="d-flex align-items-center">
                                    <p className="small text-muted text-nowrap">{item.keyNames}</p>
                                </div> */}
                            </div>
                        
                            {/* <div className="align-self-center ml-3">
                                <div className="dropdown z-index-max show">
                                    <a href="#" className="btn btn-sm btn-ico btn-link text-muted w-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                        <i className="fe-more-horizontal"></i>
                                    </a>
                                    <div className="dropdown-menu" style={{position: 'absolute', transform: 'translate3d(-122px, 36px, 0px)', top: '0px', left: '0px', wilChange: 'transform'}} x-placement="bottom-start">
                                        <a onClick={()=>{
                                            this.translate(item.title, item.content)
                                        }} className="dropdown-item d-flex align-items-center" href="#">
                                            翻译内容 <span className="ml-auto fe-edit-3"></span>
                                        </a>
                                        <a onClick={()=>{
                                            this.shareImage(item)
                                        }} className="dropdown-item d-flex align-items-center" href="#">
                                            生成图片 <span className="ml-auto fe-share-2"></span>
                                        </a>
                                    </div>
                                </div>
                            </div> */}
                    
                        </div>
                    </div>  
                </div>
            </div>
        )
    }
}

export default MessageListItem