import React from 'react';
import {observer, inject} from 'mobx-react';
import Loading from '../../component/Loading';

@inject("store")
@observer
class SignIn extends React.Component{
    render() {
        const {loginStore} = this.props.store
        const {
            loginStore : {
                loading,
                remember,
                login_name,
                login_pwd,
                error
            }} = this.props.store
        return(
            <div className="layout">
                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center no-gutters min-vh-100">
                        <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">
                            
                            <h1 className="font-bold text-center">登录</h1>
                            
                            <p className="text-center mb-6">欢迎来到俯瞰</p>

                            <div className="mb-6">
                                <div className="form-group">
                                    <label for="email" className="sr-only">账号</label>
                                    <input value={login_name} onChange={e=>loginStore.setLoginName(e.target.value)} type="email" className="form-control form-control-lg" id="email" placeholder="账号"></input>
                                </div>

                                <div className="form-group">
                                    <label for="password" className="sr-only">密码</label>
                                    <input value={login_pwd} onChange={e=>loginStore.setLoginPwd(e.target.value)} type="password" className="form-control form-control-lg" id="password" placeholder="密码"></input>
                                </div>


                                <div className="form-group d-flex justify-content-center">
                                    <div className="custom-control custom-checkbox">
                                        {remember?<input onClick={()=>loginStore.setRemember()} type="checkbox" className="custom-control-input" defaultValue={remember} id="checkbox-remember"></input>:<input onClick={()=>loginStore.setRemember()} type="checkbox" className="custom-control-input"  id="checkbox-remember"></input>}
                                        <label className="custom-control-label" for="checkbox-remember">记住我</label>
                                    </div>
                                    {/* <a href="./password-reset.html">Reset password</a> */}
                                </div>

                                {error.length > 0 ?<div className="alert alert-warning" style={{color: '#000000'}} role="alert">{error}</div>:""}

                                <button className="btn btn-lg btn-block btn-primary" type="submit" onClick={()=>loginStore.login()}>登录</button>
                            </div>

                            <p className="text-center">
                                还没有账号? <a style={{color: '#0176ff'}} onClick={()=>{
                                    this.props.history.push('/register.html')
                                }} >注册</a>.
                            </p>
                        </div>
                    </div>
                </div>
                
                {loading?<Loading></Loading>:""}

            </div>
        )
    }
}
export default SignIn