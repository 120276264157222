import Test from './test';
import MainStore from './main'
import LoginStore from './login'
import RegisterStore from './register';
import SpeicalStore from './special';
import MMStore from './multi_media';

export default {
    testStore: Test,
    mainStore: MainStore,
    loginStore: LoginStore,
    registerStore: RegisterStore,
    speicalStore: SpeicalStore,
    mmStore: MMStore
}