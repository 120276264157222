import React from 'react';
import {observer, inject} from 'mobx-react';
import Loading from '../../component/Loading'

@inject("store")
@observer
class SignUp extends React.Component{
    render() {

        const {registerStore} = this.props.store
        const {
            registerStore: {
                loading,
                error,
                register_name,
                register_pwd,
                re_register_pwd
            }} = this.props.store

        return(
            <div className="layout">

                <div className="container d-flex flex-column">
                    <div className="row align-items-center justify-content-center no-gutters min-vh-100">

                        <div className="col-12 col-md-5 col-lg-4 py-8 py-md-11">

                            
                            <h1 className="font-bold text-center">注册</h1>

                            
                            <p className="text-center mb-6">欢迎来到俯瞰</p>

                            
                            <div className="mb-6">

                                
                                <div className="form-group">
                                    <label for="name" className="sr-only">账号</label>
                                    <input value={register_name} onChange={e=>registerStore.setRegisterName(e.target.value)} type="text" className="form-control form-control-lg" id="name" placeholder="注册账号"></input>
                                </div>

                                
                                <div className="form-group">
                                    <label for="email" className="sr-only">密码</label>
                                    <input value={register_pwd} onChange={e=>registerStore.setRegisterPwd(e.target.value)} type="password" className="form-control form-control-lg" id="email" placeholder="账号密码"></input>
                                </div>

                                
                                <div className="form-group">
                                    <label for="password" className="sr-only">确认密码</label>
                                    <input value={re_register_pwd} onChange={e=>registerStore.setReRegisterPwd(e.target.value)} type="password" className="form-control form-control-lg" id="password" placeholder="确认密码"></input>
                                </div>

                                {error.length > 0 ?<div className="alert alert-warning" style={{color: '#000000'}} role="alert">{error}</div>:""}

                                <button onClick={()=>registerStore.register(this.props.history)} className="btn btn-lg btn-block btn-primary" type="submit">注册</button>
                            </div>

                            
                            <p className="text-center">
                                已经有账号了? <a style={{color: '#0176ff'}} onClick={()=>{
                                    this.props.history.push('/login.html')
                                }}>登录</a>.
                            </p>

                        </div>
                    </div>
                </div>

                {loading?<Loading></Loading>:""}
                
            </div>
        )
    }
}
export default SignUp