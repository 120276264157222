import {observable, action} from 'mobx';
import {print, callBack, set, get, date2Day, dateFormat} from '../assets/LocalStorage'
import md5 from '../utils/md5';
import Api from '../utils/Api'

class MultiMedia{
    @observable audio_data_list = []
    @observable vedio_data_list = []

    timer = null

    @action setCurrentMedia(media) {

    }
    /**
     * 加载多媒体集合数据
     * @param {*} media_id 媒体集合id
     */
    @action loadMultiMediaList(media_id){
        // 规则是 (audio|vedio)_media_id 对应的集合
        this.audio_data_list = get(`audio_${media_id}`) == null ? [] : get(`audio_${media_id}`)
        this.vedio_data_list = get(`vedio_${media_id}`) == null ? [] : get(`vedio_${media_id}`)
    }   
    /**
     * 保存数据
     * @param {*} media_id 媒体集合id
     * @param {*} type 声音 视频
     * @param {*} message 消息对象
     * {
     *    id: ""                    服务器生成多媒体id
     *    name: ""                  名字和id一样
     *    url: ""                   对应的多媒体地址 
     *    message_id: ""            消息编号
     *    message_title             消息标题
     *    message_content: ""       消息内容
     *    message_create_date: ""   消息时间
     *    create_date: ""           本地保存时间 排序 和 判断是否超期不再显示
     * }
     */
    async addMultiMedia(media_id, type, message){
       const key = `${type}_${media_id}`
       console.log(`key:${key}`)
       let id = null
       let array = null

       if(type == 'audio') {
            let isHave = false
            this.audio_data_list.map(it=>{
                if(it.message_id == message.id){
                    isHave = true
                }
            })

            if(!isHave) {
                let res = await Api.createAudio(message)

                if(res && 200 == res.code) {
                    id = res.data
                }
                array = this.resetDataList(this.audio_data_list)
      
            }

        } else if(type == 'vedio'){

            let isHave = false
            this.audio_data_list.map(it=>{
                if(it.message_id == message.id){
                    isHave = true
                }
            })

            if(!isHave) {
                let res = await Api.createVedio(message)
                console.log(res)
                if(res && 200 == res.code) {
                    id = res.data
                }
                array = this.resetDataList(this.vedio_data_list)
            }
            
       }
       console.log(`id:${id}`, `array:${JSON.stringify(array)}`)
       if(id != null && array != null){

            let it = {
                id: id,
                name: id,
                url: "",
                message_id: message.id,
                message_title: message.title,
                message_content: message.content,
                message_create_date: message.date_time,
                message_key: message.key,
                create_date: dateFormat(new Date())
            }

            console.log(it)

            array.push(it)

            set(key, array)

            this.loadMultiMediaList(media_id)
        }

    }

    resetDataList(dataList, da = null){
        let array = []
        dataList.map(data=>{
            if (da == null) {
                array.push({...data})
            } else if(da.id == data.id){
                array.push({...da})
            } else {
                array.push({...data})
            }
        })
        return array
    }

    getTaskList(media_id){
        let audio_task_list = []
        let audio_list = []
        this.audio_data_list.filter(it=>{return it.url == ""}).map(it=>{
            audio_task_list.push(
                Api.getAudioStatus(it.id)
            )
            audio_list.push({...it})
        })
        if(audio_task_list.length > 0) {
            Promise.all(audio_task_list).then(array=>{
                console.log(array)
                if(array && Array.isArray(array)) {
                    array.map((a_it, index)=>{
                        if(a_it && 200 == a_it.code) {
                            audio_list[index].url = a_it.data
                            set(`audio_${media_id}`, this.resetDataList(this.audio_data_list, audio_list[index]))
                            this.loadMultiMediaList(media_id)
                        }
                    })
                }
            }).catch(e=>{})
        }
        
        let vedio_task_list = []
        let vedio_list = []
        
        this.vedio_data_list.filter(it=>{return it.url == ""}).map(it=>{
            vedio_task_list.push(
                Api.getVedioStatus(it.id)
            )
            vedio_list.push({...it})
        })
        console.log(vedio_list)

        if(vedio_task_list.length > 0) {
            Promise.all(vedio_task_list).then(array=>{
                console.log(array)
                if(array && Array.isArray(array)) {
                    array.map((a_it, index)=>{
                        if(a_it && 200 == a_it.code) {
                            vedio_list[index].url = a_it.data.url
                            set(`vedio_${media_id}`, this.resetDataList(this.vedio_data_list, vedio_list[index]))
                            this.loadMultiMediaList(media_id)
                        }
                    })
                }
            }).catch(e=>{})
        }

    }
}
const MM = new MultiMedia()
export default MM
