import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';
import {dateFormat} from '../../assets/LocalStorage'

@inject("store")
@observer
class SideBarSearchDiv extends Component{
    render(){
        const {mainStore: {
            data_media_me,
            search_wrods,
            data_media,
            ui_side_status,
            search_sort,
            search_start_date_time,
            search_end_date_time
        }} = this.props.store
        const {mainStore} = this.props.store
        return(
            <Fragment>
                <div className="mb-6">
                    <div className="input-group">
                            <input value={search_wrods} onChange={e=>mainStore.setSearchWords(e.target.value)} type="text" className="form-control form-control-lg" placeholder="Search for messages or users..." aria-label="Search for messages or users..."></input>
                            <div className="input-group-append">
                                <div className="btn btn-lg btn-ico btn-secondary btn-minimal"  onClick={()=>{
                                        if(ui_side_status == '0' || ui_side_status == '2'){
                                            mainStore.setUiSideStatus('1')
                                        }else {
                                            if(search_wrods && search_wrods.length > 0) {
                                                mainStore.search()
                                            }
                                        }
                                    }}>
                                    <i className="fe-search"></i>
                                </div>
                            </div>
                        <div className="input-group-append">
                            <button className="btn btn-lg btn-ico btn-secondary btn-minimal"  data-toggle="collapse" data-target="#search_params">
                                <i className="fe-more-vertical"></i>
                            </button>
                        </div>
                    </div>
                </div>
                            

                {/* 搜素条件 */}
                <div id="search_params" className="collapse">
                    <div className="text-center hide-scrollbar d-flex" data-horizontal-scroll="" onClick={()=>mainStore.setUiSourceDalogShow(true)}>
                        <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6" data-toggle="modal" data-target="#invite-sources" >
                            信源筛选
                            <i className="fe-users ml-auto"></i>
                        </button>
                    </div>
                    <div className="text-center hide-scrollbar d-flex" data-horizontal-scroll="">
                        <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6 justify-content-between" data-toggle="modal" data-target="#invite-tiems">
                            时间筛选
                            <span>{`${dateFormat(search_start_date_time, 'yyyy-MM-dd')} -- ${dateFormat(search_end_date_time, 'yyyy-MM-dd')}`}</span>
                        </button>
                    </div>
                    <div className="text-center hide-scrollbar d-flex" data-horizontal-scroll="">
                            <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6 justify-content-between" data-toggle="modal" onClick={()=>mainStore.setSearchSort()} >
                                排序条件
                                <span>{search_sort == "desc" ? "降序" : "升序"}</span>
                            </button>
                        </div>
                    </div>
                            
            </Fragment>
        )
    }
}

export default SideBarSearchDiv