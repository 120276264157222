import React, {Component} from 'react'
import {observer, inject} from 'mobx-react';
import Loading from '../../component/Loading'
import {isPC} from '../../assets/LocalStorage'

import Navigation from '../Main_Navigation'
import SideBar from '../Main_SideBar'
import Message from '../Main_Message'
import SourceDalog from '../Main_Source_Dalog'
import TimeDalog from '../Main_Time_Dalog'

import './main.scss'
@inject("store")
@observer
class Main extends Component{

    render() {
        const {
            mainStore: {
                main_loading,
                data_media,
                ui_message_open,
                ui_source_dalog_show,
                search_mdeia_list,
                data_media_me,
            }} = this.props.store
        const {mainStore} = this.props.store
        // console.log(search_data_media_me)
        
        return(
            <div className="layout">
                {/* <!-- Navigation --> */}
                <div className="navigation navbar navbar-light justify-content-center py-xl-7 ">
                    <Navigation></Navigation>
                </div>
                {/* <!-- Sidebar PC--> */}
                <div className={window.innerWidth >= 768 ? "sidebar" : "sidebar sidebar_phone"}>
                    <SideBar></SideBar>
                </div>

                {/* <!-- Main Content APP--> */}
                {
                    (window.innerWidth >= 768 || ui_message_open) ? <div className="main main-visible" data-mobile-height>
                        <Message></Message>
                    </div>: ""
                }


                {ui_source_dalog_show ? <SourceDalog></SourceDalog>: ""}
                <TimeDalog></TimeDalog>
                {main_loading?<Loading></Loading>:""}
            </div> 
        )
    }    
}

export default Main