import React, {Component, Fragment} from 'react'
// import DataList from '../../static/message.json'
import {observer, inject} from 'mobx-react';
import {checkChinese, callBack, filterHTMLTag} from '../../assets/LocalStorage'
import Loading from '../../component/MinLoading'
import MessageRightSideBar from '../Main_Message_Right_SideBar';

@inject("store")
@observer
class Message extends Component{

    render(){
        const {mainStore} = this.props.store
        const {
            mainStore: {
                data_media,
                data_media_me,
                data_media_messages
            }
        } = this.props.store


        return(
            <div className="chat dropzone-form-js" id="chat-1" >
                {/* <!-- Chat: body --> */}
                <div className="chat-body">
                    {/* <!-- Chat: Header --> */}
                    <div className="chat-header border-bottom py-4 py-lg-6 px-lg-8">
                        <div className="container-xxl">
                            <div className="row align-items-center">
                                {/* <!-- Close chat(mobile) --> */}
                                <div className="col-3 d-xl-none" onClick={()=>mainStore.clearDataMedia()}>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item">
                                            <a className="text-muted px-0" href="#" data-chat="open">
                                                <i className="icon-md fe-chevron-left"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                {/* <!-- Chat photo -->  */}
                                <div className="col-6 col-xl-6">
                                    <div className="media text-center text-xl-left">
                                        <div className="avatar avatar-sm d-none d-xl-inline-block mr-5">
                                            <img src={data_media ? data_media.avatars : (data_media_me.length > 0 ? data_media_me[0].avatars: "")} className="avatar-img" alt=""></img>
                                        </div>

                                        <div className="media-body align-self-center text-truncate">
                                            <h6 className="text-truncate mb-n1">{data_media ? data_media.name : (data_media_me.length > 0 ? data_media_me[0].name: "")}</h6>
                                            {/* <small className="text-muted">35 members</small>
                                            <small className="text-muted mx-2"> • </small>
                                            <small className="text-muted">HTML, CSS, and Javascript Help</small> */}
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Chat toolbar --> */}
                                <div className="col-3 col-xl-6 text-right">
                                    <ul className="nav justify-content-end">
                                        {/* <li className="nav-item list-inline-item d-none d-xl-block mr-5">
                                            <a className="nav-link text-muted px-3" data-toggle="collapse" data-target="#chat-1-search" href="#" title="Search this chat">
                                                <i className="icon-md fe-search"></i>
                                            </a>
                                        </li> */}

                                        {/* <li className="nav-item list-inline-item d-none d-xl-block mr-3">
                                            <a className="nav-link text-muted px-3" href="#" data-chat-sidebar-toggle="#chat-1-members" title="Add People">
                                                <i className="icon-md fe-user-plus"></i>
                                            </a>
                                        </li> */}

                                        <li className="nav-item list-inline-item d-none d-xl-block mr-0">
                                            <a onClick={()=>mainStore.setMessageRightSideBarOpen(true)} className="nav-link text-muted px-3" href="#" data-chat-sidebar-toggle="#chat-1-info" title="Details">
                                                <i className="icon-md fe-more-vertical"></i>
                                            </a>
                                        </li>

                                                
                                        <li className="nav-item list-inline-item d-block d-xl-none">
                                            <div className="dropdown">
                                                <a className="nav-link text-muted px-0" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="icon-md fe-more-vertical"></i>
                                                </a>
                                                <div className="dropdown-menu">
                                                    {/* <a className="dropdown-item d-flex align-items-center" data-toggle="collapse" data-target="#chat-1-search" href="#">
                                                        Search <span className="ml-auto pl-5 fe-search"></span>
                                                    </a> */}

                                                    <a onClick={()=>mainStore.setMessageRightSideBarOpen(true)} className="dropdown-item d-flex align-items-center" href="#" data-chat-sidebar-toggle="#chat-1-info">
                                                        Chat Info <span className="ml-auto pl-5 fe-more-horizontal"></span>
                                                    </a>

                                                    {/* <a className="dropdown-item d-flex align-items-center" href="#" data-chat-sidebar-toggle="#chat-1-members">
                                                        Add Members <span className="ml-auto pl-5 fe-user-plus"></span>
                                                    </a> */}
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* <!-- Chat: Search --> */}
                    <div className="collapse border-bottom px-lg-8" id="chat-1-search">
                        <div className="container-xxl py-4 py-lg-6">
                                <div className="input-group">
                                    <input type="text" className="form-control form-control-lg" placeholder="Search this chat" aria-label="Search this chat"></input>

                                    <div className="input-group-append">
                                        <button className="btn btn-lg btn-ico btn-secondary btn-minimal" type="submit">
                                            <i className="fe-search"></i>
                                        </button>
                                    </div>
                                </div>
                        </div>
                    </div>
                    {/* <!-- Chat: Content--> */}
                    <div id="container_body" className="chat-content px-lg-8" onScroll={e=>{
                        let top = window.$(e.target).scrollTop()
                        let top_height = window.$(e.target).height()
                        let height = document.getElementById('container').scrollHeight
                        // console.log(`top:${top + top_height}  height:${height}`)
                        if((top + top_height) == height || height - (top + top_height) < 150) {
                            mainStore.setIsUpdataMessageUI(true)
                        } else {
                            mainStore.setIsUpdataMessageUI(false)
                        }
                    }}>
                        <div id="container" className="container-xxl py-6 py-lg-10">
                            
                            {/* message */}
                            {/* {this.renderMessage()} */}
                            {/* message-rigth */}
                            {/* <!-- Divider --> */}
                            
                            {/* message-img */}

                            {
                                (data_media && Array.isArray(data_media_messages)) ? data_media_messages.map((item, index) => {
                                    return <MessageItem key={`${data_media.id}-${index}`} data_media={data_media} message_item={item} index={index}></MessageItem>
                                    // return this.renderMessage(data_media, item, index)
                                }): ""
                            }


                        </div>
                    </div>
                
                    {/* <!-- Chat: DropzoneJS container --> */}
                    <div className="chat-files hide-scrollbar px-lg-8">
                        <div className="container-xxl">
                            <div className="dropzone-previews-js form-row py-4"></div>
                        </div>
                    </div>
                    {/* <!-- Chat: Footer --> */}
                    <div className="chat-footer border-top py-4 py-lg-6 px-lg-8">
                            <div className="container-xxl">

                                <div id="chat-id-1-form" action="./assets/php/upload.php" data-emoji-form="">
                                    <div className="form-row align-items-center">
                                        <div className="col">
                                            <div className="input-group">

                                                {/* <!-- Textarea --> */}
                                                <textarea id="chat-id-1-input" className="form-control bg-transparent border-0" placeholder="message..." rows="1" data-emoji-input="" data-autosize="true" style={{overflow: "hidden", overflowWrap: "break-word", resize: "none", height: "46px"}}></textarea>

                                                {/* <!-- Emoji button --> */}
                                                <div className="input-group-append">
                                                    <button className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0" type="button" data-emoji-btn="">
                                                        <img src="" data-inject-svg="" alt=""></img>
                                                    </button>
                                                </div>

                                                {/* <!-- Upload button --> */}
                                                <div className="input-group-append">
                                                    <button id="chat-upload-btn-1" className="btn btn-ico btn-secondary btn-minimal bg-transparent border-0 dropzone-button-js dz-clickable" type="button">
                                                        <img src="" data-inject-svg="" alt=""></img>
                                                    </button>
                                                </div>

                                            </div>

                                        </div>

                                        {/* <!-- Submit button --> */}
                                        <div className="col-auto">
                                            <button className="btn btn-ico btn-primary rounded-circle" type="submit">
                                                <span className="fe-send"></span>
                                            </button>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                </div>
                {/*  */}

                {/* rightSideBar chat-sidebar-visible*/}
                {data_media ? <MessageRightSideBar></MessageRightSideBar>:""}
                {/*  */}
                {/*  */}
                {/*  */}
            </div>
        )
    }

    
    renderMessage(data_media, message_item, index) {
        return(
            <div key={index} className="message">
                    {/* <!-- Avatar --> */}
                <a className="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile">
                    <img className="avatar-img" src={data_media.avatars} alt=""></img>
                </a>

                {/* <!-- Message: body --> */}
                <div className="message-body">

                    {/* <!-- Message: row --> */}
                    <div className="message-row">
                        <div className="d-flex align-items-center">

                            {/* <!-- Message: content --> */}
                            <div className="message-content bg-light">
                                {
                                   message_item.title ?  <h6 className="mb-2">{message_item.title}</h6> : ""
                                }
                                
                                
                                <div>{message_item.content}</div>

                                <div className="mt-1">
                                     <small className="opacity-65">{message_item.date_time}</small>
                                </div>
                            </div>
                            {/* <!-- Message: content --> */}

                            {/* <!-- Message: dropdown --> */}
                            {/* <div className="dropdown">
                                <a className="text-muted opacity-60 ml-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe-more-vertical"></i>
                                </a>

                                <div className="dropdown-menu">
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        Edit <span className="ml-auto fe-edit-3"></span>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        Share <span className="ml-auto fe-share-2"></span>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" href="#">
                                        Delete <span className="ml-auto fe-trash-2"></span>
                                    </a>
                                </div>
                            </div> */}
                            {/* <!-- Message: dropdown --> */}

                    </div>
                </div>
               {/* <!-- Message: row --> */}

            </div>
            {/* <!-- Message: Body --> */}
        </div>
                            
        )
    }

    renderMessageRight() {
        return(
            <div className="message message-right">
                                    {/* <!-- Avatar --> */}
                                    <div className="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block">
                                        <img className="avatar-img" src="./assets/images/avatars/12.jpg" alt=""></img>
                                    </div>

                                    {/* <!-- Message: body --> */}
                                    <div className="message-body">

                                        {/* <!-- Message: row --> */}
                                        <div className="message-row">
                                            <div className="d-flex align-items-center justify-content-end">

                                                {/* <!-- Message: dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="text-muted opacity-60 mr-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe-more-vertical"></i>
                                                    </a>

                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Edit <span className="ml-auto fe-edit-3"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Share <span className="ml-auto fe-share-2"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Delete <span className="ml-auto fe-trash-2"></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: dropdown --> */}

                                                {/* <!-- Message: content --> */}
                                                <div className="message-content bg-primary text-white">
                                                    <div>Yeah, I'm going to meet a friend of mine at the department store. I have to buy some presents for my parents.</div>

                                                    <div className="mt-1">
                                                        <small className="opacity-65">8 mins ago</small>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: content --> */}

                                            </div>
                                        </div>
                                        {/* <!-- Message: row --> */}

                                        {/* <!-- Message: row --> */}
                                        <div className="message-row">
                                            <div className="d-flex align-items-center justify-content-end">

                                                {/* <!-- Message: dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="text-muted opacity-60 mr-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe-more-vertical"></i>
                                                    </a>

                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Edit <span className="ml-auto fe-edit-3"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Share <span className="ml-auto fe-share-2"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Delete <span className="ml-auto fe-trash-2"></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: dropdown --> */}

                                                {/* <!-- Message: content --> */}
                                                <div className="message-content bg-primary text-white">
                                                    <div className="media">
                                                        <a href="#" className="icon-shape mr-5">
                                                            <i className="fe-paperclip"></i>
                                                        </a>
                                                        <div className="media-body overflow-hidden flex-fill">
                                                            <a href="#" className="d-block text-truncate font-medium text-reset">bootstrap.min.js</a>
                                                            <ul className="list-inline small mb-0">
                                                                <li className="list-inline-item">
                                                                    <span className="t">79.2 KB</span>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    <span className="text-uppercase">js</span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: content --> */}

                                            </div>
                                        </div>
                                        {/* <!-- Message: row --> */}

                                    </div>
                                    {/* <!-- Message: body --> */}
                                </div>
                            
        )
    }

    renderDivider(){
        return(
            <div className="message-divider my-9 mx-lg-5">
                <div className="row align-items-center">
                    <div className="col">
                        <hr/>
                    </div>
                    <div className="col-auto">
                        <small className="text-muted">Today</small>
                    </div>
                    <div className="col">
                        <hr/>
                    </div>
                </div>
            </div> 
        )
    }

    renderMessageImge() {
        return(
            <div className="message">
                                {/* <!-- Avatar --> */}
                                <a className="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile">
                                    <img className="avatar-img" src="./assets/images/avatars/7.jpg" alt=""></img>
                                </a>

                                {/* <!-- Message: body --> */}
                                    <div className="message-body">

                                        {/* <!-- Message: row --> */}
                                        <div className="message-row">
                                            <div className="d-flex align-items-center">

                                                {/* <!-- Message: content --> */}
                                                <div className="message-content bg-light w-100">
                                                    <h6 className="mb-2">William Wright shared 3 photos:</h6>
                                                    <div className="form-row py-3">
                                                        <div className="col">
                                                            <img className="img-fluid rounded" src="https://themes.2the.me/Boomerang/1.2/./assets/images/team/1.jpg" data-action="zoom" alt=""></img>
                                                        </div>
                                                        <div className="col">
                                                            <img className="img-fluid rounded" src="https://themes.2the.me/Boomerang/1.2/./assets/images/team/2.jpg" data-action="zoom" alt=""></img>
                                                        </div>
                                                        <div className="col">
                                                            <img className="img-fluid rounded" src="https://themes.2the.me/Boomerang/1.2/./assets/images/team/3.jpg" data-action="zoom" alt=""></img>
                                                        </div>
                                                    </div>

                                                    <div className="mt-1">
                                                        <small className="opacity-65">8 mins ago</small>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: content --> */}

                                                {/* <!-- Message: dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="text-muted opacity-60 ml-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe-more-vertical"></i>
                                                    </a>

                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Edit <span className="ml-auto fe-edit-3"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Share <span className="ml-auto fe-share-2"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Delete <span className="ml-auto fe-trash-2"></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: dropdown --> */}

                                            </div>
                                        </div>
                                        {/* <!-- Message: row --> */}

                                        {/* <!-- Message: row --> */}
                                        <div className="message-row">
                                            <div className="d-flex align-items-center">

                                                {/* <!-- Message: content --> */}
                                                <div className="message-content bg-light">
                                                    <div>Yeah, I'm going to meet a friend of mine at the department store. I have to buy some presents for my parents.</div>

                                                    <div className="mt-1">
                                                        <small className="opacity-65">8 mins ago</small>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: content --> */}

                                                {/* <!-- Message: dropdown --> */}
                                                <div className="dropdown">
                                                    <a className="text-muted opacity-60 ml-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i className="fe-more-vertical"></i>
                                                    </a>

                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Edit <span className="ml-auto fe-edit-3"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Share <span className="ml-auto fe-share-2"></span>
                                                        </a>
                                                        <a className="dropdown-item d-flex align-items-center" href="#">
                                                            Delete <span className="ml-auto fe-trash-2"></span>
                                                        </a>
                                                    </div>
                                                </div>
                                                {/* <!-- Message: dropdown --> */}

                                            </div>
                                        </div>
                                        {/* <!-- Message: row --> */}

                                    </div>
                                    {/* <!-- Message: body --> */}
                                </div>
                        
        )
    }

}
/**
 * 每条消息
 */
@inject("store")
@observer
class MessageItem extends React.Component{
    state = {
        loading: false,
        translate_title: "", // 翻译标题
        translate_content: "", // 翻译的内容
        share_img_url: "",
        translate_share_image_url: ""
    }
    isTitleChinese = true
    isContentChinese = true

    translate(title, content) {
        const {mainStore} = this.props.store
        let request_list = []
        if(content || content.length) {
            request_list.push(mainStore.translate(content, this.isContentChinese))
        }
        if(title && title.length > 0) {
            request_list.push(mainStore.translate(title, this.isTitleChinese)) 
        }
        this.setState({loading: true})
        Promise.all(request_list).then(data=>{
            let res_content = data[0]
            res_content = callBack(res_content)
            let tc = ""
            let tt = ""
            if(res_content && 200 == res_content.code) {
                tc = res_content.data
            }
            if(data.length == 2) {
                let res_title = data[1]
                res_title = callBack(res_title)
                if(res_title && 200 == res_title.code) {
                    tt = res_title.data
                }
            }
            this.setState({
                translate_title: tt,
                translate_content: tc,
                loading: false
            })
        }).catch(e=>{
            this.setState({loading: false})
        })
    }

    shareImage(item, content, call=(url)=>{
        this.setState({
            loading: false,
            share_img_url: url
        })
    }) {
        const {mainStore} = this.props.store 
        this.setState({loading: true})
        // item.content = content
        mainStore.shareTxtToServer(item, content).then(data=>{
            data = callBack(data)
            if(data && 200 == data.code) {
                if(call){
                    call(data.data)
                }
            }else {
                this.setState({
                    loading: false,
                })
            }
        }).catch(e=>{
            this.setState({loading: false})
        })
    }

    shareAudio(item, content, call){
        const {mmStore, mainStore} = this.props.store
        const {data_media} = this.props
        mmStore.addMultiMedia(data_media.id, 'audio', item)
        mainStore.setMessageRightSideBarOpen(true)
    }

    shareVedio(item, content, call){
        const {mmStore, mainStore} = this.props.store
        const {data_media} = this.props
        mmStore.addMultiMedia(data_media.id, 'vedio', item)
        mainStore.setMessageRightSideBarOpen(true)
    }

    renderDivider(tab){
        let style = {height:'1px', borderBottom:'1px dashed #4d4d4d'}
        return(
            <div className="message-divider mt-1 mb-1">
                <div className="row align-items-center">
                    <div className="col" style={style}>
                        <hr/>
                    </div>
                    <div className="col-auto">
                        <small className="text-muted">{tab}</small>
                    </div>
                    <div className="col" style={style}>
                        <hr/>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {data_media, message_item, index} = this.props
        this.isTitleChinese = checkChinese(message_item.title)
        this.isContentChinese = checkChinese(message_item.content)
        return(
            <div key={index} className="message" >
                
                {/* <!-- Avatar --> */}
                <a className="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile">
                    <img className="avatar-img" src={data_media.avatars} alt=""></img>
                </a>

                {/* <!-- Message: body --> */}
                <div className="message-body" >
                    
                    {/* <!-- Message: row --> */}
                    <div className="message-row">
                        <div className="d-flex align-items-center">

                            {/* <!-- Message: content --> */}
                            <div className="message-content bg-light" style={{position: 'relative'}}>
                                {this.state.loading?<Loading></Loading>: ""}
                                {
                                    message_item.title ?  <h6 className="mb-2">{message_item.title}</h6> : ""
                                }
                                <div>{filterHTMLTag(message_item.content)}</div>
                                <div className="mt-1">
                                    <small className="opacity-65">{message_item.date_time}</small>
                                </div>


                                {this.state.share_img_url != "" ? this.renderDivider('可分享的图片') : ""}
                                {this.state.share_img_url != "" ? 
                                <div className="form-row py-3">
                                    <div className="col">
                                        <img className="img-fluid rounded" src={this.state.share_img_url} data-action="zoom" alt=""></img>
                                    </div>
                                </div>: ""}


                                {(this.state.translate_title != "" || this.state.translate_content != "") ? this.renderDivider('译文') : ""}
                                {(this.state.translate_title != "" || this.state.translate_content != "") ? <div onClick={()=>{
                                            this.shareImage(message_item, this.state.translate_content, (url)=>{
                                                this.setState({
                                                    loading: false,
                                                    translate_share_image_url: url
                                                })
                                            })
                                        }} style={{color: 'blue'}} className="p2 d-flex flex-row align-items-center justify-content-center"><span style={{color: '#fff'}}  className="mx-2 ml-auto fe-share-2"></span></div>:""}
                                
                                {this.state.translate_title != "" ? <h6 className="mb-2">{this.state.translate_title}</h6>:""}
                                {this.state.translate_content != "" ? <div className="mt-1">{this.state.translate_content}</div>:""}
                                
                                {this.state.translate_share_image_url != "" ? this.renderDivider('可分享的图片') : ""}
                                {this.state.translate_share_image_url != "" ? 
                                <div className="form-row py-3">
                                    <div className="col">
                                        <img className="img-fluid rounded" src={this.state.translate_share_image_url} data-action="zoom" alt=""></img>
                                    </div>
                                </div>: ""}
                                
                            </div>

                            

                            {/* <!-- Message: content --> */}

                            {/* <!-- Message: dropdown --> */}
                            <div className="dropdown">
                                <a className="text-muted opacity-60 ml-3" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i className="fe-more-vertical"></i>
                                </a>

                                <div className="dropdown-menu">
                                    <a className="dropdown-item d-flex align-items-center" onClick={()=>{
                                        this.translate(message_item.title, message_item.content)
                                    }}>
                                        翻译内容 <span className="ml-auto fe-edit-3"></span>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" onClick={()=>this.shareImage(message_item, message_item.content)}>
                                        生成图片 <span className="ml-auto fe-share-2"></span>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" onClick={()=>this.shareAudio(message_item, message_item.content)}>
                                        生成音频 <span className="ml-auto glyphicon glyphicon-cd"></span>
                                    </a>
                                    <a className="dropdown-item d-flex align-items-center" onClick={()=>this.shareVedio(message_item, message_item.content)}>
                                        生成视频 <span className="ml-auto glyphicon glyphicon-facetime-video"></span>
                                    </a>
                                    {/* <a className="dropdown-item d-flex align-items-center" href="#">
                                        Delete <span className="ml-auto fe-trash-2"></span>
                                    </a> */}
                                </div>
                            </div>
                            {/* <!-- Message: dropdown --> */}

                    </div>
                </div>
            {/* <!-- Message: row --> */}

            </div>
                {/* <!-- Message: Body --> */}
        </div>
     
        )
    }
}



export default Message