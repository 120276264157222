import React from 'react';
import {observer, inject} from 'mobx-react';
import Loading from '../../component/Loading';

@inject("store")
@observer
class ResetPwd extends React.Component{
    render(){
        return(
        <div class="layout">

        <div class="container d-flex flex-column">
            <div class="row align-items-center justify-content-center no-gutters min-vh-100">

                <div class="col-12 col-md-5 col-lg-4 py-8 py-md-11">

                    
                    <h1 class="font-bold text-center">Password Reset</h1>

                    
                    <p class="text-center mb-6">Enter your email address to reset password.</p>

                    
                    <div class="mb-6">

                        
                        <div class="form-group">
                            <label for="email" class="sr-only">Email Address</label>
                            <input type="email" class="form-control form-control-lg" id="email" placeholder="Enter your email"></input>
                        </div>
                        
                        <div class="form-group">
                            <label for="email" class="sr-only">Email Address</label>
                            <input type="email" class="form-control form-control-lg" id="email" placeholder="Enter your email"></input>
                        </div>
                        
                        <button class="btn btn-lg btn-block btn-primary" type="submit">Send Reset Link</button>
                    </div>

                    
                    <p class="text-center">
                        Already have an account? <a href="./signin.html">Sign in</a>.
                    </p>

                </div>
            </div> 
        </div>

        </div>
        )
    }
}

export default ResetPwd