import React, {Component} from 'react';

// 媒体图标
class MediaItemCircular extends Component{
    render() {
        const {item, selected, onClick} = this.props
        return(
            <div onClick={()=>onClick?onClick(item):""} className="d-block text-reset mt-6 mr-6 ml-6">
                <div className={selected ? "avatar avatar-sm mb-3 avatar-online" : "avatar avatar-sm mb-3"}>
                    <img className="avatar-img" src={item.avatars} alt=""></img>
                </div>
                <div className="small">{item.name}</div>
            </div>
        )
    }
}

export default MediaItemCircular