import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';
import MessageListItem from '../Main_SideBar_Message_List_Item'
import Loading from '../../component/MinLoading'
@inject("store")
@observer
class Special_Plane extends Component{



    componentDidMount(){
        const {speicalStore} = this.props.store
        speicalStore.loadSpeicalList()
    }
   
    render() {
        const {
            mainStore: {
                ui_side_status,
            },
            speicalStore: {
                speical,
                
            }
        } = this.props.store
        return(
            <div className="tab-pane fade h-100 show active a-fadein" id="tab-content-dialogs" role="tabpanel" style={ui_side_status == 4 ? {visibility: "visible"} : {display: "none"}}>
                <div className="d-flex flex-column h-100">
                    <div className="hide-scrollbar">
                        {
                            speical ? this.renderSpeicalData() : this.renderSpeical()
                        }
                    </div>
                </div>
            </div>
    
        )
    }

    renderSpeicalData(){
        const {
            speicalStore: {
                speical_data_list,
                loading
            }
        } = this.props.store
        const {mainStore, speicalStore} = this.props.store
        return(
            <div className="container-fluid py-6" style={{position: 'relative'}}>
                {loading?<Loading></Loading>: ""}
                <div onClick={()=>speicalStore.setSpeical(null)} className="mb-3 d-flex flex-row align-items-center">
                    <i className="icon-md fe-chevron-left"></i>
                    返回
                </div>
                {
                    speical_data_list.map((message, index) =>{
                        return <MessageListItem key={index} item={message}></MessageListItem>
                    })
                }
            </div>
        )
    }

    renderSpeical(){
        const {
            mainStore: {
                ui_side_status,
                search_mdeia_list
            },
            speicalStore: {
                speical_list,
                speical_name,
                speical_media_list,
                speical_key_words,
                message
            }
        } = this.props.store
        const {mainStore, speicalStore} = this.props.store
        return(
                <div className="container-fluid py-6">
                            {/* title */}
                            <h2 className="font-bold mb-6">定制专题</h2>
                            {
                                message ? <small className="mb-3">{message}</small> : ""
                            }
                            {/* <!-- Chats --> */}
                            <nav className="nav d-block list-discussions-js mb-n6">
                                <ul className="nav nav-tabs nav-justified mb-6" role="tablist">
                                    <li className="nav-item">
                                        <a href="#create-group-details" className="nav-link active" data-toggle="tab" role="tab" aria-selected="true">设置</a>
                                    </li>

                                    <li className="nav-item">
                                        <a href="#create-group-members" className="nav-link" data-toggle="tab" role="tab" aria-selected="false">列表</a>
                                    </li>
                                </ul>



                                <div className="tab-content" role="tablist">
                                        <div id="create-group-details" className="tab-pane fade show active" role="tabpanel">
                                            
                                                <div className="form-group">
                                                    <label className="small" >名称</label>
                                                    <input onChange={e=>speicalStore.setSpeicalName(e.target.value)} value={speical_name} className="form-control form-control-lg" name="new-chat-title" id="new-chat-title" type="text" placeholder="专题名" />
                                                </div>

                                                <div className="form-group">
                                                    <label className="small" >数据源 (optional)</label>
                                                    <div onClick={()=>mainStore.setUiSourceDalogShow(true)} className="form-control form-control-lg" name="new-chat-topic" id="new-chat-topic" type="text" placeholder="媒体选择" >媒体选择</div>
                                                </div>

                                                <div className="form-group mb-4">
                                                    <label className="small" >关键词</label>
                                                    <textarea onChange={e=>speicalStore.setSpeicalKeyWord(e.target.value)} value={speical_key_words} className="form-control form-control-lg" name="new-chat-description" id="new-chat-description" rows="6" placeholder="多个关键词请用空格分割"></textarea>
                                                </div>

                                                <div className="form-group mt-4">
                                                    <div className="container-fluid">
                                                        <button onClick={()=>speicalStore.addSpeical(search_mdeia_list)} className="btn btn-lg btn-primary btn-block" type="submit">定制</button>
                                                    </div>
                                                </div>
                                        </div>

                                        <div id="create-group-members" className="tab-pane fade" role="tabpanel">
                                            <nav className="list-group list-group-flush mb-n6">

                                                

                                               {
                                                   speical_list.map((speical, index) =>{
                                                        return (
                                                            <div key={index} className="card mb-6" style={{cursor: 'pointer'}} >
                                                                <div className="card-body">
        
                                                                <div className="media" onClick={(e)=>{
               
                                                                    e.stopPropagation()
                                                                    speicalStore.setSpeical(speical)
                                                                }}>
                                                                    
                                                                    
                                                                    {/* <div className="avatar mr-5">
                                                                        <img className="avatar-img" src="./assets/images/avatars/7.jpg" alt="Zane Mayes" />
                                                                    </div> */}
                                                                    
        
                                                                    <div className="media-body align-self-center mr-6">
                                                                        <h6 className="mb-0">{speical.name}</h6>
                                                                        <small className="text-muted">定制专题</small>
                                                                    </div>
        
                                                                    <div className="align-self-center ml-auto" onClick={(e)=>{
                                            
                                                                        e.stopPropagation()  
                                                                        speicalStore.deleteSpeical(speical)
                                                                    }}>
                                                                        <div className="custom-control custom-checkbox cursor">
                                                                            <span className="ml-auto fe-trash-2"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
        
                                                                </div>
        
                                                            
                                                                {/* <label className="stretched-label" ></label> */}
                                                            </div>
                                                        
                                                        )
                                                   })
                                               }
                                               

                                            </nav>
                                        </div>        
                                </div>
                            
                                
                            
                            
                                
                            </nav>
                        </div>
                        
        )
    }

}

export default Special_Plane