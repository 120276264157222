import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';
// import Users from '../../static/users.json'
import MediaListItem from '../Main_Mdeia_List_Item'
import SearchDiv from '../../component/SideBar_Search_Div'

@inject("store")
@observer
class My_Media_Plane extends Component{

    componentDidMount(){
        const {mainStore} = this.props.store
        mainStore.initMainData()
    }
   
    render() {
        const {mainStore: {
            data_media_me,
            search_wrods,
            data_media,
            ui_side_status
        }} = this.props.store
        const {mainStore} = this.props.store
        return(
            <div className="tab-pane fade h-100 show active a-fadein" id="tab-content-dialogs" role="tabpanel" style={ui_side_status == 0 ? {visibility: "visible"} : {display: "none"}}>
                <div className="d-flex flex-column h-100">
                    <div className="hide-scrollbar">
                        <div className="container-fluid py-6">
                            {/* title */}
                            <h2 className="font-bold mb-6">My Media</h2>
                            <SearchDiv></SearchDiv>
                            {/* <!-- Chats --> */}
                            <nav className="nav d-block list-discussions-js mb-n6">
                                {
                                    data_media_me.map((item, index) => {
                                        return(
                                            <MediaListItem 
                                                task
                                                selectMedia={data_media}
                                                key={index} 
                                                item={item}
                                                onClick={()=>mainStore.setDataMedia(item)}
                                                ></MediaListItem>    
                                        )
                                    })
                                }
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
    
        )
    }

}

export default My_Media_Plane