import axios from 'axios';
const BaseUrl = 'http://localhost:3721/';
const axiosRequest = axios.create({
    baseURL: BaseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});
let config = {
    timeout: 1000 * 60
}
export default {
    /**
     * @param {*} uploadId 上传文件ID
     * @param {*} path 分片上传文件URL
     * @param {*} file 文件对象
     * @param {*} token  
     * @param {*} progress function
     * @param {*} scuess function
     */
    fileSliceUpload: (uploadId, path, file, token, scuess, fila, progressCallback = null)=>{
        const eachSize = 2 * 1024 * 1024; // 每个chunks的大小
        const blockCount = Math.ceil(file.size / eachSize); // 分片总数
        const axiosArray = []; // axiosPromise数组
        for(let i=0; i<blockCount; i++){
            let start = i * eachSize,
                end = Math.min(file.size,start+eachSize);
            // 构建表单
            const form = new FormData();
            form.append('file',file.slice(start, end));
            form.append('chunk',i);
            form.append('id',uploadId);
            // ajax提交 分片，此时 content-type 为 multipart/form-data
            const axiosOptions = {
                headers:{
                    'Content-Type': 'multipart/form-data;charset=UTF-8',
                    'Authorization': token
                },
                onUploadProgress:(e)=>{
                    // eslint-disable-next-line no-unused-expressions
                    progressCallback ? progressCallback(blockCount,i,e) : ""
                },
                timeout: 1000 * 60 * 10
            };
            // 加入到 Promise 数组中
            axiosArray.push(axios.post(BaseUrl + path, form, axiosOptions));
        }

        // 所有分片上传后，请求合并分片文件
        axios.all(axiosArray).then(()=>{
            // 开始查询uploadId的状态
            // eslint-disable-next-line no-unused-expressions
            scuess ? scuess() : ""
        }).catch(e=>{
            //请求出错了
            // eslint-disable-next-line no-unused-expressions
            fila ? fila(e) : ""
        });
    },
    upload: (path, files,  token, progress = null)=>{
        return new Promise((resolve, reject)=>{
            axiosRequest.post(path, files, {
                headers:{
                    'Content-Type': 'multipart/form-data;charset=UTF-8',
                    'Authorization': token
                },
                onUploadProgress: event=>{
                    // eslint-disable-next-line no-unused-expressions
                    progress ? progress(event) : "";
                },
                timeout: 1000 * 60 * 10
            }).then(res=>{
                resolve(res)
            }).catch(e=>{
                reject(null)
                console.log(e)
            })
        }) 
    },
    post: (api, data, token = "")=>{
        return new Promise((resolve, reject) => {
            if(token && token !== ""){
                config = {
                    ...config,
                    headers:{
                        'Content-Type': 'application/json',
                        "Authorization": token
                    },
                }
            }
            // console.log(config)
            axiosRequest.post(api, data, config).then(res=>{
                if(200 === res.status){
                    resolve(res.data)
                }else{
                    reject(null)
                }    
            }).catch(error=>{
                reject(null)
            })
        });
    },
    get: (api, token = "")=>{
        return new Promise((resolve, reject)=>{

            if(token && token !== ""){
                config = {
                    ...config,
                    headers:{
                        'Content-Type': 'application/json',
                        "Authorization": token
                    },
                }
            }
            // console.log(config)
            axiosRequest.get(api, config).then(res=>{
                if(200 === res.status){
                    resolve(res.data)
                }else{
                    reject(null)
                } 
            }).catch(error=>{
                reject(null)
            })
        })
    },
    HOST_TEA: `https://www.vulcandata.cn/chatroom`,
    HOST_BASE: `https://www.vulcandata.cn/`,
    HOST: `https://www.vulcandata.cn/sso`,
    TAG: `Uiy1fjSuPqw7meBsoJk9X811gx1CL27h`,
    // HOST_TEA: `http://47.104.211.22/chatroom`,
    // HOST_BASE: `http://47.104.211.22/`,
    postApi: (api, data, token="") =>{
        // console.log(`url: ${api}`)
        // console.log(`data: ${JSON.stringify(data)}`)
        // console.log(`token: ${token}`)
        return new Promise((resolve, reject) => {
            if(token && token !== ""){
                config = {
                    ...config,
                    headers:{
                        'Content-Type': 'application/json',
                        "Authorization": token
                    },
                }
            }
            // console.log(config)
            axios.create().post(api, data, config).then(res=>{
                if(200 === res.status){
                    resolve(res.data)
                }else{
                    reject(null)
                }    
            }).catch(error=>{
                reject(null)
            })
        }); 
    },
    getApi: (api, token="") => {
        return new Promise((resolve, reject)=>{

            if(token && token !== ""){
                config = {
                    ...config,
                    headers:{
                        'Content-Type': 'application/json',
                        "Authorization": token
                    },
                }
            }
            // console.log(config)
            axios.create().get(api, config).then(res=>{
                if(200 === res.status){
                    resolve(res.data)
                }else{
                    reject(null)
                } 
            }).catch(error=>{
                reject(null)
            })
        })
    }
}
