import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';
import {getUserInfo, clearUserInfo} from '../../assets/LocalStorage'

@inject("store")
@observer
class My_Media_Plane extends Component{

    componentDidMount(){
        
    }
   
    render() {
        const {mainStore: {
            ui_side_status
        }} = this.props.store
        const {mainStore} = this.props.store
        const {
            nickName,
            avatars
        } = getUserInfo()
        return(
            <div className="tab-pane fade h-100 show active a-fadein" id="tab-content-dialogs" role="tabpanel" style={ui_side_status == 3 ? {visibility: "visible"} : {display: "none"}}>
                <div className="d-flex flex-column h-100">
                    <div className="hide-scrollbar">
                        <div className="container-fluid py-6">
                            {/* title */}
                            <h2 className="font-bold mb-6">Profile</h2>
                            {/* <!-- Chats --> */}
                            <nav className="nav d-block list-discussions-js mb-n6">
                                
                                <div className="card mb-6">
                                    <div className="card-body">
                                        <div className="text-center py-6">
                                            
                                            <div className="avatar avatar-xl mb-5">
                                                <img className="avatar-img" src={avatars} alt="" />
                                            </div>

                                            <h5>{nickName}</h5>
                                            <p className="text-muted">这家伙很懒，什么都没留下来....</p>
                                        </div>
                                    </div>
                                </div>
                           

                                {/* <div className="card mb-6">
                                    <div className="card-body">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item px-0 py-6">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Country</p>
                                                        <p>Warsaw, Poland</p>
                                                    </div>
                                                    <i className="text-muted icon-sm fe-globe"></i>
                                                </div>
                                            </li>

                                            <li className="list-group-item px-0 py-6">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Phone</p>
                                                        <p>+39 02 87 21 43 19</p>
                                                    </div>
                                                    <i className="text-muted icon-sm fe-mic"></i>
                                                </div>
                                            </li>

                                            <li className="list-group-item px-0 py-6">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Email</p>
                                                        <p>anna@gmail.com</p>
                                                    </div>
                                                    <i className="text-muted icon-sm fe-mail"></i>
                                                </div>
                                            </li>

                                            <li className="list-group-item px-0 py-6">
                                                <div className="media align-items-center">
                                                    <div className="media-body">
                                                        <p className="small text-muted mb-0">Time</p>
                                                        <p>10:03 am</p>
                                                    </div>
                                                    <i className="text-muted icon-sm fe-clock"></i>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                             */}
                                <div className="form-row">
                                    <div className="col">
                                        <button type="button" className="btn btn-lg btn-block btn-basic d-flex align-items-center">
                                            Settings
                                            <span className="fe-settings ml-auto"></span>
                                        </button>
                                    </div>

                                    <div className="col">  
                                        <button onClick={()=>{
                                            clearUserInfo()
                                            window.location.href = '/'
                                        }} type="button" className="btn btn-lg btn-block btn-basic d-flex align-items-center">
                                            Logout
                                            <span className="fe-log-out ml-auto"></span>
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
    
        )
    }

}

export default My_Media_Plane