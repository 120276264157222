import Api from '../utils/Api'
import {observable, action} from 'mobx';
import {print, checkEmail, saveUserInfo} from '../assets/LocalStorage';
class LoginStore{

    @observable error = ""

    // 页面加载进度条开关
    @observable loading = false

    // 记录登录状态
    @observable remember = false
    @action setRemember() {
        this.remember = !this.remember
    }

    // 用户登录名字
    @observable login_name = ""
    @action setLoginName(_loginName){
        this.error = ""
        this.login_name = _loginName
    }
    
    // 用户登录密码
    @observable login_pwd = ""
    @action setLoginPwd(_pwd){
        this.error = ""
        this.login_pwd = _pwd
    }

    // 登录请求
    @action login(){
        this.error = ""
        // print(`name: ${this.login_name}`)
        // print(`pwd: ${this.login_pwd}`)
        // print(`remember: ${this.remember}`)
        
        if(this.checkLoginName(this.login_name) && this.checkPwd(this.login_pwd)) {
            this.loading = true
            Api.signin(this.login_name, this.login_pwd).then(data=>{
                print(data)
                if(data && 200 === data.code) {
                    saveUserInfo(data.data)
                    window.location.href = '/'
                }else{
                    this.error = data.message
                }
                this.loading = false
            })
        }

    }

    // 
    checkLoginName(login_name){
        if(!login_name || login_name.length < 6) {
            this.error = "用户名长度不能少于6位"
            print(`error: ${this.error}`)
            return false
        } 
        return true
    }
    
    checkPwd(pwd){
        if(!pwd || pwd.length < 6) {
            this.error = "密码不能少于6位"
            print(`error: ${this.error}`)
            return false
        }
        return true
    }

}
const login = new LoginStore()
export default login