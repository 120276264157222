import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';

@inject("store")
@observer
class My_Media_All_Plane extends Component{


    componentDidMount(){
        const {mainStore} = this.props.store
        mainStore.getMediaAll()
    }

    // 全部信源队列
    render() {
        const {mainStore} = this.props.store
        const {
            mainStore: {
                data_media_all,
                data_media_me
            }
        }  = this.props.store
        return(
            <div className="tab-pane fade h-100 show active a-fadein" id="tab-content-friends" role="tabpanel">
                <div className="d-flex flex-column h-100">
                    <div className="hide-scrollbar">
                        <div className="container-fluid py-6">
                            {/* <!-- Title --> */}
                            <h2 className="font-bold mb-6">All Media</h2>
                            {/* <!-- Search --> */}
                            <form className="mb-6">
                                <div className="input-group">
                                    <input type="text" className="form-control form-control-lg" placeholder="Search for messages or users..." aria-label="Search for messages or users..."></input>
                                    <div className="input-group-append">
                                        <button className="btn btn-lg btn-ico btn-secondary btn-minimal" type="submit">
                                                <i className="fe-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {/* <!-- Button --> */}
                            {/* <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6" data-toggle="modal" data-target="#invite-friends">
                                Invite friends
                                <i className="fe-users ml-auto"></i>
                            </button> */}
                                
                            {/* <!-- Friends --> */}
                            <nav className="mb-n6">
                                {/* tag */}
                                {/* <div className="mb-6">
                                    <small className="text-uppercase">A</small>
                                </div> */}
                                {/* card */}
                                {/* <div className="card mb-6"> */}
                                    {
                                        data_media_all.map((item, index) => {
                                            // 判断媒体是否被关注了
                                            let isHave = false
                                            data_media_me.map(it => {
                                                if(it.id == item.id) {
                                                    isHave = true
                                                }
                                            })
                                            return <All_Media_List_Item 
                                                        key={index} 
                                                        data={item} 
                                                        isHave={isHave}
                                                        onClick={()=>{
                                                            console.log('123123')
                                                            if(isHave) {
                                                                mainStore.removeMedia(item)
                                                            }else {
                                                                mainStore.addMedia(item)
                                                            }
                                                        }}
                                                        ></All_Media_List_Item>
                                        })
                                    }
                                {/* </div> */}
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
                
        )
    }
}


class All_Media_List_Item extends Component{
    render() {
        const {data, isHave, onClick} = this.props

        return(
            <div className="card mb-6">
            <div className="card-body">

                <div className="media">
                    {/* avatar-online */}
                    <div className="avatar  mr-5">
                        <img className="avatar-img" src={data.avatars} alt="Anna Bridges"></img>
                    </div>
                                                    
                                                    
                    <div className="media-body align-self-center">
                        <h6 className="mb-0">{data.name}</h6>
                        <small className="text-muted">{isHave ? "已订阅" : "未订阅"}</small>
                    </div>

                    <div className="align-self-center ml-5">
                        <div className="dropdown z-index-max">
                            <a href="#" className="btn btn-sm btn-ico btn-link text-muted w-auto" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fe-more-vertical"></i>
                            </a>
                            <div className="dropdown-menu">
                                <a className="dropdown-item d-flex align-items-center" onClick={()=>onClick?onClick():""}>
                                    {isHave ? "取消订阅" : "订阅"}  <span className="ml-auto fe-edit-2"></span>
                                </a> 
                                
                                
                                {/* <a className="dropdown-item d-flex align-items-center" href="#">
                                    Delete <span className="ml-auto fe-trash-2"></span>
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>                   
        )
    }
}

export default My_Media_All_Plane