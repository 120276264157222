import Api from '../utils/Api'
import {observable, action} from 'mobx';
import {print} from '../assets/LocalStorage'

class Register{
    @observable loading = false
    
    @observable error = ""

    @observable register_name = ""
    @action setRegisterName(name){
        this.error = ""
        this.register_name = name
    }

    @observable register_pwd = ""
    @action setRegisterPwd(pwd){
        this.error = ""
        this.register_pwd = pwd
    }

    @observable re_register_pwd = ""
    @action setReRegisterPwd(pwd){
        this.error = ""
        this.re_register_pwd = pwd
    }

    @action register(history) {
        this.error = ""
        if(this.checkRegisterName(this.register_name) && this.checkPwdAndPwd(this.register_pwd, this.re_register_pwd)) {
            this.loading = true
            Api.register(this.register_name, this.register_pwd).then(data=>{
                print(data)
                if(data && 200 === data.code) {
                    history.push('/login.html')
                    // window.location.href = '/login.html'
                } else {
                    this.error = data.message
                }  
                this.loading = false
            }).catch(e=>{
                this.loading = false
            })
        }
    }

    checkRegisterName(name) {
        if(!name || name.length < 6){
            this.error = "用户名不能少于6位"
            return false
        }
        return true
    }

    checkPwdAndPwd(pwd,re_pwd){

        if(!pwd || pwd.length < 6) {
            this.error = "密码不能少于6位"
            return false
        }

        if(!re_pwd || re_pwd.length < 6) {
            this.error = "密码和确认密码不一致"
            return false
        }

        if(re_pwd != re_pwd) {
            this.error = "密码和确认密码不一致"
            return false
        }

        return true
    }

}
const register = new Register()
export default register