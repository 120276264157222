import React from 'react';
import { BrowserRouter as Router, Route, Redirect} from "react-router-dom"; //Switch ,Prompt, Link, Redirect
import { Provider} from 'mobx-react';
import Store from './store';
import {getUserInfo} from './assets/LocalStorage';
// import Test from './pages/Test';
import SignInPage from './pages/SignIn'
import SignUpPage from './pages/SignUp'
import ResetPwdPage from './pages/ResetPwd'
import MainPage from './pages/Main'

// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap/dist/css/bootstrap-grid.css';
// import 'bootstrap/dist/css/bootstrap-reboot.css';
// import 'bootstrap/dist/js/jquery.min';
// import 'bootstrap/dist/js/bootstrap';
import './App.scss';
import './animation.css'

function isLogin(){
  if(null == getUserInfo()) {
    return false
  }
  return true
}

class App extends React.Component{
  render() {
    return (
      <Provider store={Store}>
          <Router>
            <div>
              <Route path='/' exact render={props=>{
                if(isLogin()){
                  return <MainPage {...props}></MainPage>
                }else {
                  return <Redirect to={{pathname: '/login.html'}} />
                }
              }}></Route>
              <Route path='/login.html'  component={SignInPage}></Route>
              <Route path='/register.html'  component={SignUpPage}></Route>
              <Route path='/password-reset.html' component={ResetPwdPage}></Route>
            </div>
          </Router>
      </Provider>
    )
  }
}

export default App;
