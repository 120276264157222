import React, {Component, Fragment} from 'react'
import {observer, inject} from 'mobx-react';
import MediaListItem from '../Main_Mdeia_List_Item'
import MessageListItem from '../Main_SideBar_Message_List_Item'
import SearchDiv from '../../component/SideBar_Search_Div'
@inject("store")
@observer
class Search_Plane extends Component{


    componentDidMount() {
        const {mainStore: {
            search_wrods
        }} = this.props.store
        const {mainStore} = this.props.store
        if(search_wrods && search_wrods.length > 0) {
           mainStore.search()
        }
    }


    // 搜索结果页面
    render() {
        const {mainStore: {
            search_wrods,
            ui_side_status,
            search_media_list,
            search_message_list
        }} = this.props.store
        const {mainStore} = this.props.store
        return(
            <div className="tab-pane fade h-100 show active a-fadein" id="tab-content-search" role="tabpanel">
                <div className="d-flex flex-column h-100">
                    <div className="hide-scrollbar">
                        <div className="container-fluid py-6">
                            
                            <h2 className="font-bold mb-6">Search</h2>
                            
                            {/* <div className="mb-6">
                                <div className="input-group">
                                    <input value={search_wrods} onChange={e=>mainStore.setSearchWords(e.target.value)} type="text" className="form-control form-control-lg" placeholder="Search for messages or users..." aria-label="Search for messages or users..."></input>
                                    <div className="input-group-append">
                                        <div className="btn btn-lg btn-ico btn-secondary btn-minimal"  onClick={()=>{mainStore.setUiSideStatus('1')}}>
                                            <i className="fe-search"></i>
                                        </div>
                                    </div>
                                    <div className="input-group-append">
                                        <button className="btn btn-lg btn-ico btn-secondary btn-minimal"  data-toggle="collapse" data-target="#search_params">
                                            <i className="fe-more-vertical"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                             */}

                            {/* 搜素条件 */}
                            {/* <div id="search_params" className="collapse">
                                <div className="text-center hide-scrollbar d-flex" data-horizontal-scroll="">
                                    <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6" data-toggle="modal" data-target="#invite-sources">
                                        信源筛选
                                        <i className="fe-users ml-auto"></i>
                                    </button>
                                </div>
                                <div className="text-center hide-scrollbar d-flex" data-horizontal-scroll="">
                                    <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6" data-toggle="modal" data-target="#invite-tiems">
                                        时间筛选
                                        <i className="fe-users ml-auto"></i>
                                    </button>
                                </div>
                                <div className="text-center hide-scrollbar d-flex" data-horizontal-scroll="">
                                    <button type="button" className="btn btn-lg btn-block btn-secondary d-flex align-items-center mb-6" data-toggle="modal" data-target="#invite-friends">
                                        排序条件
                                        <i className="fe-users ml-auto"></i>
                                    </button>
                                </div>
                            </div> */}
                            
                            <SearchDiv></SearchDiv>

                            <ul className="nav nav-tabs nav-justified mb-6" role="tablist">
                                <li className="nav-item" onClick={()=>mainStore.setUiSideStatus('1-1')}>
                                    <a href="#create-group-details" className="nav-link active" data-toggle="tab" role="tab" aria-selected="true">媒体</a>
                                </li>
                                <li className="nav-item" onClick={()=>mainStore.setUiSideStatus('1-2')}>
                                    <a href="#create-group-members" className="nav-link" data-toggle="tab" role="tab" aria-selected="false">消息</a>
                                </li>
                            </ul>
                            {
                              (ui_side_status == '1' || ui_side_status == '1-1') ? 
                                search_media_list.map((item, index) => {
                                    return (
                                        <MediaListItem key={index} item={item}></MediaListItem>
                                    )
                                })
                              :
                              search_message_list.map((item, index) => {
                                  return (
                                      <MessageListItem key={index} item={item}></MessageListItem>
                                    )
                              })  
                            }
                            
                        </div>
                    </div>
                </div>
            
                
                
            </div>
        )
    }
}

export default Search_Plane