import React, {Component} from 'react'
import {observer, inject} from 'mobx-react';
import Users from '../../static/users.json'
import MinLoading from '../../component/MinLoading'
import MyMediaPlane from '../Main_SideBar_My_Media_Plane'
import AllMediaPlane from '../Main_SideBar_All_Media_Plane'
import SearchPlane from '../Main_SideBar_Search_Plane'
import MyPlane from '../Main_SideBar_My_Plane';
import SpecialPlane from '../Main_SideBar_Special';
@inject("store")
@observer
class SideBar extends Component{
    render(){
        const {
            mainStore: {
                ui_side_status,
                side_loading
            }} = this.props.store
        return(
            <div className="tab-content h-100" role="tablist" style={{position: 'relative'}}>
                {/* 全部消息列表 */}
                {/* {this.renderSideSource()} */}
                {/* 已经订阅消息 */}
                {/* {this.renderSideDefault()} */}
                {/* 搜索结果界面 */}
                {/* {this.rednerSideSearch()}                   */}
                <MyMediaPlane></MyMediaPlane>
                {(ui_side_status == "1" || ui_side_status == "1-1" || ui_side_status == "1-2") ? <SearchPlane></SearchPlane>:""}
                {ui_side_status == "2" ? <AllMediaPlane></AllMediaPlane>:""}
                <MyPlane></MyPlane>
                <SpecialPlane></SpecialPlane>
                {side_loading?<MinLoading></MinLoading>:""}
            </div>
        )
    }
    
}
export default SideBar