import React, {Component} from 'react'

class MinLoading extends Component{
    render(){
        return (
            <div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center" style={{position: 'absolute', top: '0px', left: '0px', zIndex: 9999}}>
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}

export default MinLoading