import React, {Component} from 'react'
import {observer, inject} from 'mobx-react';
import {dateFormat, date2Day} from '../../assets/LocalStorage'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// 信源筛选
@inject("store")
@observer
class TimeDalog extends Component{

    render() {
        const {mainStore: {
            search_start_date_time, 
            search_end_date_time
        }} = this.props.store

        const {mainStore} = this.props.store

        return(
            <div className="modal fade" id="invite-tiems" tabIndex="-1" role="dialog" style={{display: "none"}} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <div className="media flex-fill">
                                {/* <div className="icon-shape rounded-lg bg-primary text-white mr-5">
                                    <i className="fe-users"></i>
                                </div> */}
                                <div className="media-body align-self-center">
                                    <h5 className="modal-title">时间筛选</h5>
                                    {/* <p className="small">Invite colleagues, clients and friends.</p> */}
                                </div>
                            </div>

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div style={{marginBottom: '8px'}}>仅支持最大7日内查询</div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend" style={{marginRight: '8px'}}>
                                    <span className="input-group-text" id="basic-addon1">开始时间</span>
                                </div>
                                <DatePicker className="form-control datetimepicker-input"
                                    selected={search_start_date_time}
                                    onSelect={date=>{mainStore.setSearchStartDate(date)}} //when day is clicked
                                    // onChange={date=>{console.log(date)}} //only when value has changed
                                />
                                {/* <input defaultValue={dateFormat(search_start_date_time, 'yyyy-MM-dd')}  id="date" type="text" className="form-control datetimepicker-input" ></input> */}
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend" style={{marginRight: '8px'}}>
                                    <span className="input-group-text" id="basic-addon1">结束时间</span>
                                </div>
                                <DatePicker className="form-control datetimepicker-input"
                                    selected={search_end_date_time}
                                    onSelect={date=>{mainStore.setSearchEndDate(date)}} //when day is clicked
                                    // onChange={date=>{console.log(date)}} //only when value has changed
                                />
                                {/* <input defaultValue={dateFormat(search_end_date_time, 'yyyy-MM-dd')} id="date2" type="text" className="form-control datetimepicker-input" ></input> */}
                            </div>
                            {/* <div className="hide-scrollbar d-flex flex-row flex-wrap" style={{maxHeight: '200px'}}>
                                123
                                
                            </div> */}
                            {/* <form action="#">
                                <div className="form-group">
                                    <label for="invite-email" className="small">Email</label>
                                    <input type="text" className="form-control form-control-lg" id="invite-email">
                                </div>

                                <div className="form-group mb-0">
                                    <label for="invite-message" className="small">Invitation message</label>
                                    <textarea className="form-control form-control-lg" id="invite-message" data-autosize="true" style="overflow: hidden; overflow-wrap: break-word; resize: none;"></textarea>
                                </div>
                            </form> */}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn  btn-primary d-flex align-items-center text-center" data-dismiss="modal">
                                确定
                                {/* <i className="fe-user-plus ml-auto"></i> */}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default TimeDalog