import React, {Component} from 'react'
// import Users from '../../static/users.json';
import MediaItem from '../Main_Media_Circular';
import {observer, inject} from 'mobx-react';

@inject("store")
@observer
class SourceDalog extends Component{
    state = {
        removeList: []
    }
    render() {
        const {mainStore} = this.props.store
        const {
            mainStore: {
                search_mdeia_list
            },
            speicalStore: {
                speical_media_list
            }
        } = this.props.store

        // console.log(`SourceDalog render`)
        return(
            <div className="modal fade show a-fadein" id="invite-sources" tabIndex="-1" role="dialog"  aria-hidden="true" style={{display: 'block'}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <div className="modal-header">
                            <div className="media flex-fill">
                                {/* <div className="icon-shape rounded-lg bg-primary text-white mr-5">
                                    <i className="fe-users"></i>
                                </div> */}
                                <div className="media-body align-self-center">
                                    <h5 className="modal-title">信源筛选</h5>
                                    {/* <p className="small">Invite colleagues, clients and friends.</p> */}
                                </div>
                            </div>

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true" onClick={()=>mainStore.setUiSourceDalogShow(false)}>×</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div>筛选媒体默认全部，最少选择一个媒体</div>
                            <div className="hide-scrollbar d-flex flex-row flex-wrap" style={{maxHeight: '200px'}}>
                                {
                                    search_mdeia_list.map((item, index) => {
                                        return (
                                            <MediaItem 
                                                onClick={()=>item.onClick(item, speical_media_list)}
                                                selected={item.selected} 
                                                key={index} 
                                                item={item}></MediaItem>
                                        )
                                    })
                                }
                                
                            </div>
                            {/* <form action="#">
                                <div className="form-group">
                                    <label for="invite-email" className="small">Email</label>
                                    <input type="text" className="form-control form-control-lg" id="invite-email">
                                </div>

                                <div className="form-group mb-0">
                                    <label for="invite-message" className="small">Invitation message</label>
                                    <textarea className="form-control form-control-lg" id="invite-message" data-autosize="true" style="overflow: hidden; overflow-wrap: break-word; resize: none;"></textarea>
                                </div>
                            </form> */}
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn  btn-primary d-flex align-items-center text-center" onClick={()=>mainStore.setUiSourceDalogShow(false)}>
                                确定
                                {/* <i className="fe-user-plus ml-auto"></i> */}
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default SourceDalog